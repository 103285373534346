import { useStore } from "react-redux";
import { wlaOrganisationApi } from "../features/wlaOrganisation/wlaOrganisationSlice";
import { RootState, Store } from "../store";
import { Organisation } from "../types/db";
import { useEffect, useState } from "react";

const defaultOrganisationDomain = "my";

export const getOrganisationDomain = (url: string | undefined): string | undefined => {
  if (url === undefined) {
    return;
  }
  const regex = /^http[s]?:\/\/(local[.])?(.*?)([.]bulbshare[.](com|dev))/;
  const regexArr = regex.exec(url);
  if (!regexArr) {
    return;
  }
  const organisationDomain = regexArr[2];

  const isEphemeralDeploymentDomain = organisationDomain.startsWith("my-bulbshare-pr-");
  if (isEphemeralDeploymentDomain) {
    return;
  }

  return organisationDomain;
};

export const getIsWlaApp = (domainOrganisation?: string) =>
  domainOrganisation !== defaultOrganisationDomain;

export const fetchWlaOrganisation = async (store: Store, domain?: string) => {
  let data = wlaOrganisationApi.endpoints.fetchWlaOrganisation.select(domain)({
    wlaOrganisation: store.getState().wlaOrganisation,
  });
  if (data.isUninitialized) {
    data = await store.dispatch(
      wlaOrganisationApi.endpoints.fetchWlaOrganisation.initiate(domain)
    );
  }
  return data?.data;
};

export const selectWlaOrganisation = ({ wlaOrganisation }: RootState, domain?: string) =>
  wlaOrganisationApi.endpoints.fetchWlaOrganisation.select(domain)({
    wlaOrganisation,
  });

export const useGetSavedWlaOrganisation = (domain?: string) => {
  const store = useStore<RootState>();
  return selectWlaOrganisation(store.getState(), domain);
};

export const useGetWlaOrganisation = (domain?: string) => {
  const store = useStore<RootState>();
  const [organisation, setOrganisation] = useState<Organisation | undefined>();
  const [isInitialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    (async function fetchOrganisation() {
      const organisation = await fetchWlaOrganisation(store, domain);
      setOrganisation(organisation);
      setInitialized(true);
    })();
  }, [domain]);
  return { organisation, isInitialized };
};

export const getOrganisationKeyValue = <K extends keyof Organisation>(
  organisation: Organisation | undefined,
  key?: K
): Organisation[K] | undefined => {
  if (!key || !organisation) {
    return;
  }
  return organisation[key];
};
