import env from "#env";

const { VITE_FINGERPRINT_API_KEY, NODE_ENV } = env;

const url = `https://fpjscdn.net/v3/${VITE_FINGERPRINT_API_KEY}`;

export default async function getFingerprint() {
  const fpPromise = await import(/* @vite-ignore */ url);

  const fp = await fpPromise.load({
    region: "eu",
    endpoint: NODE_ENV === "production" ? "https://fp.bulbshare.com" : undefined,
  });
  const { visitorId } = await fp.get();
  return visitorId;
}
