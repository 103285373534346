import { PaginatedResponse } from "#customTypes/pagination";

export enum UnsplashPhotoOrientation {
  All = "all",
  Landscape = "landscape",
  Portrait = "portrait",
  Squarish = "squarish",
}

export interface SearchUnsplashPhotosRequest {
  query: string;
  page: number;
  perPage: number;
  orientation?: UnsplashPhotoOrientation;
}

export type SearchUnsplashPhotosResponse = PaginatedResponse<UnsplashPhoto>;

export interface UnsplashPhoto {
  id: string;
  width: number;
  height: number;
  alt_description: string;
  user: {
    name: string;
    links: {
      html: string;
    };
  };
  urls: {
    raw: string;
    full: string;
    regular: string;
    thumb: string;
  };
}
