import React from "react";

import {
  getOrganisationDomain,
  useGetSavedWlaOrganisation,
} from "../../../../../utils/organisation";
import AuthSeparator from "./AuthSeparator";
import AuthenticationStrategies from "./AuthenticationStrategies";
import { useSearchParams } from "react-router-dom";
import { FACEBOOK_RE_REQUEST_KEY } from "#constants/common";

const SSOLogin = () => {
  const domainOrganisation = getOrganisationDomain(window.location.href);

  const { data: organisation } = useGetSavedWlaOrganisation(domainOrganisation);
  const [searchParams] = useSearchParams();
  const errorType = searchParams.get("errorType");

  if (!!organisation && organisation.ssoEnabled) {
    // hide SSO login for WLA apps with no domain setup
    return null;
  }

  return (
    <>
      <AuthSeparator />
      <AuthenticationStrategies
        rerequestFacebookAuth={errorType === FACEBOOK_RE_REQUEST_KEY}
      />
    </>
  );
};

export default SSOLogin;
