import React from "react";
import { FormattedMessage } from "react-intl";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";

import { AvenirAndHeavyText } from "#components/common/styled";
import EmailCustomButton from "./EmailCustomButton";

import { getUrlSearchParams } from "../../../../helper";
import { CheckYourEmail, Link, Wrapper } from "./styled";
import LoginTitle from "../../../common/LoginComponents/LoginTitle";
import LoginFootnoteWrapper from "../../../common/LoginComponents/LoginFootnoteWrapper";
import {
  EMAIL_PROVIDERS,
  EMAIL_PROVIDERS_LINK,
  EMAIL_PROVIDER_DOMAINS,
} from "#constants/common";

const getEmailProvider = (emailDomain: string) =>
  EMAIL_PROVIDER_DOMAINS.find(
    ({ domains, provider }) =>
      (provider !== EMAIL_PROVIDERS.APPLE || isMobile) && domains.includes(emailDomain)
  );

export default function EmailSentView() {
  const [searchParams] = useSearchParams();
  const { email: emailParam } = getUrlSearchParams(searchParams) || {};
  const intlPrefix = "pages.email_login.confirm";
  const regexDomain =
    /((outlook|hotmail|live|msn|microsoft|office365|exchange|onmicrosoft|gmail|googlemail|yahoo|aol|icloud|apple)[.]|me.com)/gm;
  const emailDomain = emailParam?.match(regexDomain);

  const emailProvider =
    emailDomain && emailDomain.length ? getEmailProvider(emailDomain[0]) : null;

  return (
    <>
      <Wrapper>
        <LoginTitle email={emailParam} prefixIntl="pages.email_sent" />

        {emailProvider ? (
          <EmailCustomButton
            title={emailProvider.provider}
            link={EMAIL_PROVIDERS_LINK[emailProvider.provider]}
          />
        ) : (
          <CheckYourEmail>
            <FormattedMessage id={`${intlPrefix}.button.check_email`} />
          </CheckYourEmail>
        )}

        <LoginFootnoteWrapper>
          <AvenirAndHeavyText fontSize="inherit" lineHeight="inherit">
            <FormattedMessage id={`${intlPrefix}.note.p1`} />
          </AvenirAndHeavyText>
          <AvenirAndHeavyText fontSize="inherit" lineHeight="inherit">
            <FormattedMessage
              id={`${intlPrefix}.note.p2`}
              values={{
                b: (chunk) => <Link to={`/signin`}>{chunk}</Link>,
              }}
            />
          </AvenirAndHeavyText>
        </LoginFootnoteWrapper>
      </Wrapper>
    </>
  );
}
