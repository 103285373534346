import { Dispatch, SetStateAction, useState } from "react";

import { validateEmail } from "../../../utils";
import { APIResponse } from "../../../types/types";
import { APIErrorDetails } from "#shared/components/api/errors";

type UseEmailFormProps = {
  defaultEmail: string;
  onSubmit: (email: string) => Promise<APIResponse<any> | APIErrorDetails | undefined>;
  onSuccess?: () => void;
};

export type UseEmailFormOutputProps = {
  sendingEmail: boolean;
  hasEmailBeenSent: boolean;
  useErrorMessage: [string | undefined, Dispatch<SetStateAction<string | undefined>>];
  useEmail: [string, Dispatch<SetStateAction<string>>];
  onSendingEmail: () => Promise<void>;
};

export default function useEmailForm({
  defaultEmail,
  onSubmit,
  onSuccess,
}: UseEmailFormProps): UseEmailFormOutputProps {
  const [email, setEmail] = useState<string>(defaultEmail);
  const [hasEmailBeenSent, setHasEmailBeenSent] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [sendingEmail, setSendingEmail] = useState<boolean>(false);

  const onSendingEmail = async () => {
    //  validation
    if (!validateEmail(email)) {
      if (!email) {
        setErrorMessage("errors.email.required");
      } else {
        setErrorMessage("errors.email.invalid");
      }
      return;
    }

    // send email
    setSendingEmail(true);
    const response = await onSubmit(email);
    setSendingEmail(false);
    if (response && "status" in response && response.status === "error") {
      setErrorMessage(response.data.error_details);
    } else {
      if (onSuccess) onSuccess();
      setHasEmailBeenSent(true);
      setErrorMessage("");
    }
  };

  return {
    sendingEmail,
    hasEmailBeenSent,
    useErrorMessage: [errorMessage, setErrorMessage],
    useEmail: [email, setEmail],
    onSendingEmail,
  };
}
