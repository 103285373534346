import { Box, Button, ButtonProps, SxProps, Theme, styled } from "@mui/material";
import { LoadingButton } from "#shared/components/ui";
import button from "#shared/config/theme/button";
import { toRem } from "#shared/utils";
import useTranslation from "#utils/useTranslation";
import React, { ReactNode } from "react";
import colors from "#shared/config/theme/colors";

interface Props {
  confirmButtonText?: JSX.Element | string;
  confirmButtonProps?: Omit<
    ButtonProps,
    "variant" | "size" | "sx" | "children" | "onClick"
  > & { isLoading?: boolean };
  cancelButtonText?: JSX.Element | string;
  cancelButtonProps?: Omit<
    ButtonProps,
    "variant" | "size" | "sx" | "children" | "onClick"
  >;
  onConfirmButtonClick?: () => void;
  onCancelButtonClick?: () => void;
  sx?: SxProps<Theme>;
  children?: ReactNode;
}

export default function ModalFooter({
  confirmButtonText,
  confirmButtonProps,
  cancelButtonText,
  cancelButtonProps,
  onConfirmButtonClick,
  onCancelButtonClick,
  sx,
  children,
}: Props) {
  const { t } = useTranslation();
  const { isLoading, ...rest } = confirmButtonProps || { isLoading: false };

  return (
    <Wrapper sx={sx}>
      {(cancelButtonText || onCancelButtonClick) && (
        <Button
          color="primary"
          variant="text"
          size="small"
          sx={{ minWidth: button.width.s }}
          {...cancelButtonProps}
          onClick={onCancelButtonClick}
        >
          {cancelButtonText || t("ui.buttons.cancel")}
        </Button>
      )}
      {!confirmButtonProps?.hidden && (confirmButtonText || onConfirmButtonClick) && (
        <LoadingButton
          color="primary"
          variant="contained"
          size="small"
          sx={{ minWidth: button.width.s }}
          loading={!!isLoading}
          onClick={onConfirmButtonClick}
          {...rest}
        >
          {confirmButtonText || t("ui.buttons.confirm")}
        </LoadingButton>
      )}
      {children}
    </Wrapper>
  );
}

const Wrapper = styled(Box)(
  ({ theme }) => `
  display: flex;
  gap: 0.75rem;
  justify-content: flex-end;
  align-items: center;
  padding: var(--modal-padding, ${toRem(20)});
  background: var(--modal-background, ${colors.base.white});
  border-top: 1px solid #dfdfdf;

  ${theme.breakpoints.down("sm")} {
    flex-direction: column-reverse;

    & > button {
      width: 100%;
    }
  }
`
);
