import React, { useState } from "react";
import { AIChatMessageProps } from "#components/common/AIChat/types";
import AIChatMessageContent from "#components/common/AIChat/components/message/AIChatMessageContent";
import { Box, IconButton } from "@mui/material";
import IconPencil from "#assets/svgs/pencil.svg?react";
import IconCheck from "#assets/svgs/check.svg?react";
import useAIChatTemplateForm from "#components/common/AIChat/hooks/useAIChatTemplateForm";
import { FormProvider } from "#shared/context/FormContext";
import { formatAIChatTemplatePrompt } from "#components/common/AIChat/utils";
import { useFetchCountriesQuery } from "#features/common/commonSlice";
import { useAIChatProvider } from "#components/common/AIChat/context/AIChatProvider";
import { AI_CHAT_STATIC_MESSAGES } from "#components/common/AIChat/consts";
import useAIChatSubmitPrompt from "#components/common/AIChat/hooks/useAIChatSubmitPrompt";
import BriefTemplateForm from "#components/common/AIChat/components/message/TemplateMessage/components/BriefTemplateForm";
import BriefTemplateSubmitButton from "#components/common/AIChat/components/message/TemplateMessage/components/BriefTemplateSubmitButton";

interface Props extends AIChatMessageProps {
  onClose?: () => void;
}

export default function AIChatTemplateMessageContent({
  role,
  content,
  metadata,
  onClose,
}: Props) {
  const { loading, addMessages } = useAIChatProvider();
  const { submitTextPrompt } = useAIChatSubmitPrompt();

  const form = useAIChatTemplateForm(metadata?.data);
  const { formValues, hasChanges, validate, resetForm } = form;

  const isSubmittable = !!metadata?.data;

  const { data: countries = [] } = useFetchCountriesQuery();

  const [isEditing, setIsEditing] = useState(false);

  const handleOpenEdit = () => {
    setIsEditing(true);
  };

  const handleConfirmEdit = async () => {
    if (!hasChanges) {
      setIsEditing(false);
      return;
    }

    const valid = validate();

    if (!valid) {
      return;
    }

    try {
      const prompt = formatAIChatTemplatePrompt(formValues, countries);
      resetForm();
      setIsEditing(false);
      addMessages([AI_CHAT_STATIC_MESSAGES.ModifyTemplate]);
      await submitTextPrompt(prompt, true);
    } catch (error) {
      console.error(error);
    }
  };

  const headerActions = () => {
    if (!isSubmittable) {
      return null;
    }

    if (isEditing) {
      return (
        <IconButton size="small" disabled={loading} onClick={handleConfirmEdit}>
          <IconCheck />
        </IconButton>
      );
    }

    return (
      <IconButton size="small" disabled={loading} onClick={handleOpenEdit}>
        <IconPencil />
      </IconButton>
    );
  };

  return (
    <AIChatMessageContent
      HeaderActions={headerActions()}
      role={role}
      metadata={metadata}
      content={!isEditing ? content : ""}
      className="-full-width"
    >
      <FormProvider value={form}>
        {isEditing && <BriefTemplateForm />}
        {isSubmittable && (
          <Box sx={{ mt: isEditing ? 4 : 2 }}>
            <BriefTemplateSubmitButton
              onCreateBrief={onClose}
              metadata={metadata?.data}
            />
          </Box>
        )}
      </FormProvider>
    </AIChatMessageContent>
  );
}
