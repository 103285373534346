import { ClickAwayListener, IconButton, styled } from "@mui/material";
import React, { ReactNode, useState } from "react";
import IconCircleInfo from "#assets/svgs/circle-info.svg?react";
import Tooltip from "../Tooltip";
import classNames from "classnames";

interface Props {
  title: string;
  invisible?: boolean;
  children?: ReactNode;
}

export default function InfoButton({ title, invisible, children }: Props) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleToggleTooltip = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpen((prev) => !prev);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          title={title}
          open={open}
          placement="top"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          onClose={handleTooltipClose}
          disableInteractive
        >
          <StyledButton
            disableRipple
            className={classNames({ "--invisible": invisible })}
            onClick={handleToggleTooltip}
          >
            {children || <IconCircleInfo />}
          </StyledButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}

const StyledButton = styled(IconButton)`
  padding: 0.25rem;

  &.--invisible {
    background: transparent;
    padding-inline: 0;

    &:hover {
      background: transparent;
    }
  }

  svg {
    width: 1.25rem !important;
    height: 1.25rem !important;
  }
`;
