import React from "react";
import { ChannelDetails } from "#customTypes/channel";

import ChannelJoinLeaveActions from "#components/pages/ChannelDetails/ChannelHeader/components/ChannelJoinleaveActions";
import ChannelShareButton from "#components/pages/ChannelDetails/ChannelHeader/components/ChannelShareButton";

interface Props {
  channel: ChannelDetails;
}

export default function ChannelUserActions({ channel }: Props) {
  return (
    <>
      <ChannelShareButton />
      <ChannelJoinLeaveActions channel={channel} />
    </>
  );
}
