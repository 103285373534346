import env from "#env";
import { post } from "#shared/components/api/http";
import { APIResponse } from "../../types/types";
import { APIErrorDetails, HttpApiCallErrorType } from "#shared/components/api/errors";
import getFingerprint from "../../utils/getFingerprint";
import { apiHeaders } from "../client";

type TokenByEmailBody = {
  args: {
    email: string;
    redirect: string;
    component?: string;
    device_type: number;
    device_unique_id: string;
    device_name: string | undefined;
    device_os: string | undefined;
    magicLinkParams: Record<string, any>;
  };
};

async function sendMagicLinkByEmail({
  email,
  redirectPath = "/",
  component,
  magicLinkParams = {},
}: {
  email: string;
  redirectPath?: string;
  component?: string;
  magicLinkParams: Record<string, any>;
}): Promise<APIResponse<any> | APIErrorDetails | undefined> {
  const fingerprintId = magicLinkParams.fingerprintId || (await getFingerprint());
  const headers = await apiHeaders.getWlaHeaders();
  try {
    return await post<TokenByEmailBody, APIResponse<any>>(
      `${env.VITE_VUE_APP_BS_API_BASE_URL}/email_connect`,
      {
        args: {
          email,
          redirect: `${env.VITE_BASE_URL_UI}${redirectPath}`,
          component,
          device_type: 3,
          device_unique_id: fingerprintId,
          device_name: navigator?.userAgent,
          device_os: navigator?.platform,
          magicLinkParams: {
            ...magicLinkParams,
            fingerprintId,
            email,
          },
        },
      },
      { headers }
    );
  } catch (e) {
    return (e as HttpApiCallErrorType)?.errorDetails;
  }
}

export default sendMagicLinkByEmail;
