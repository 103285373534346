import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeAllToasts, removeToast, selectToast } from "#features/toast/toastSlice";
import Toast from "#shared/components/ui/Toast";
import { ClickAwayListener, Portal, styled } from "@mui/material";

const ToastContainer = () => {
  const dispatch = useDispatch();
  const toasts = useSelector(selectToast);

  const canCloseRef = useRef(false);
  const closeTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (toasts.length > 0) {
      canCloseRef.current = false;
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
      closeTimeoutRef.current = setTimeout(() => {
        canCloseRef.current = true;
      }, 500);
    }
  }, [toasts]);

  const handleClose = (id: number) => () => {
    dispatch(removeToast(id));
  };

  const handleClickAway = () => {
    if (!canCloseRef.current) {
      return;
    }

    dispatch(removeAllToasts());
  };

  return (
    <Portal>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Wrapper onClick={handleClickAway}>
          {toasts.map((toast) => (
            <Toast
              key={toast.id}
              {...toast}
              open={true}
              onClose={() => handleClose(toast.id)}
            />
          ))}
        </Wrapper>
      </ClickAwayListener>
    </Portal>
  );
};

const Wrapper = styled("div")(
  ({ theme }) => `
  position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1800;

  ${theme.breakpoints.down("md")} {
		left: 0;
		transform: none;
		width: 100%;

		.MuiSnackbar-root {
			width: calc(100% - 1rem);
		}
	}

  display: flex;
  flex-direction: column-reverse;
	gap: 0.5rem;

	.MuiSnackbar-root {
		position: relative;
	}
`
);

export default ToastContainer;
