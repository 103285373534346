import Chip from "@mui/material/Chip";
import React, { useState } from "react";
import { FormHelperText, SxProps, Theme, css, styled } from "@mui/material";
import { toRem } from "../../../utils";
import baseTheme from "../../../config/theme";

interface Props extends React.HTMLAttributes<HTMLInputElement> {
  tags: string[];
  limit?: number;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  sx?: SxProps<Theme>;
  setTags: (tags: string[]) => void;
  validateValue?: (value: string) => boolean;
  onValueChange?: (value: string) => void;
}

export default function TagsInput({
  tags,
  limit,
  children,
  error,
  disabled,
  placeholder,
  sx,
  setTags,
  validateValue,
  onValueChange,
  ...props
}: Props) {
  const [input, setInput] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const addTags = (value: string): boolean => {
    if (!value) return false;
    const tagsArray = value.split(",").map((tag) => tag.trim());

    const validTags = tagsArray.filter(
      (c) => c.length && !tags.includes(c) && (!validateValue || validateValue(c))
    );

    // If no valid tags, return false
    if (!validTags.length) return false;

    // If adding the new tags would exceed the limit, return false
    if (tags.length + validTags.length > (limit || Infinity)) return false;

    setTags([...tags, ...validTags]);
    setInput("");
    return true;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Add tag when comma or enter is pressed
    if (!!input?.length && (e.key === "," || e.key === "Enter" || e.key === " ")) {
      // Prevent default behaviour when comma or enter is pressed
      const tagAdded = addTags(input);

      if (tagAdded) {
        e.preventDefault();
      }

      return;
    }
    // Remove last tag when backspace is pressed and input is empty
    if (!input.length && e.key === "Backspace") {
      const lastValue = tags[tags.length - 1];
      setTags(tags.slice(0, -1));
      setInput(lastValue);
      e.preventDefault();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
    onValueChange?.(e.target.value);
  };

  const handleDelete = (tagToDelete: string) => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  return (
    <>
      <TagsInputWrapper
        className="tagsInputWrapper"
        focused={isFocused}
        error={!!error}
        disabled={disabled}
        sx={sx}
      >
        <TagsWrapper>
          {tags.map((tag) => (
            <Chip
              variant="filled"
              color="primary"
              key={tag}
              label={tag}
              onDelete={() => handleDelete(tag)}
            />
          ))}

          <Input
            type="text"
            {...props}
            value={input}
            disabled={disabled}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
              setIsFocused(false);
              addTags(input);
            }}
            placeholder={!tags.length ? placeholder : undefined}
          />
        </TagsWrapper>

        {children}
      </TagsInputWrapper>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
}

const TagsInputWrapper = styled("div")<{
  focused: boolean;
  error: boolean;
  disabled?: boolean;
}>(({ theme, error, focused, disabled }) => {
  const defaultBorderColor = () => {
    if (disabled) return baseTheme.colors.input.disabled;
    if (error) return theme.palette.error.main;
    if (focused) return theme.palette.primary.main;
    return baseTheme.colors.input.border;
  };

  return css`
    --input-border-color: ${defaultBorderColor()};

    display: flex;
    flex-wrap: wrap;
    border-radius: ${baseTheme.rounding.default};
    padding: 0.65rem 0.75rem;
    min-height: ${toRem(34)};
    background-color: ${baseTheme.colors.input.background};
    border: 1px solid var(--input-border-color);
    transition: border-color 0.2s ease-in-out;
    justify-content: space-between;

    ${!disabled &&
    !error &&
    `
       &:hover {
        --input-border-color: ${theme.palette.primary.main};
      }
      `}
  `;
});

const TagsWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: ${toRem(6)};
  flex-grow: 1;
`;

const Input = styled("input")`
  font-family: Avenir;
  flex: 1;
  font-size: 0.875rem;
  border: none;
  outline: none;
  background: transparent;
  padding: 0.25rem 0.125rem;
  flex-grow: 1;

  &::placeholder {
    color: ${baseTheme.colors.input.placeholder};
  }
`;
