import { PaginatedResponse, PaginationRequest } from "#customTypes/pagination";

export type AdminOrganisation = {
  id: number;
  name: string;
  isPersonalOrganisation: boolean;
  logo: string | null;
};

export enum SearchOrganisationsFilter {
  All = "all",
  Organisations = "organisations",
  Personal = "personal",
}

export interface SearchOrganisationsRequest extends PaginationRequest {
  type: SearchOrganisationsFilter;
  search?: string;
}

export type SearchOrganisationsResponse = PaginatedResponse<AdminOrganisation>;
