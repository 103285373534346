import { client } from "#apis/axios";
import {
  GetMyFollowedChannelsRequest,
  GetMyFollowedChannelsResponse,
  JoinLeaveChannelRequest,
} from "#customTypes/channel";

export namespace ChannelsApi {
  const BASE_PREFIX = "/community/channels";

  export const getFollowedChannels = async (
    params: GetMyFollowedChannelsRequest
  ): Promise<GetMyFollowedChannelsResponse> => {
    const { data } = await client.get<GetMyFollowedChannelsResponse>(
      `${BASE_PREFIX}/my-followed-channels`,
      { params, headers: { WLA: params.organisationId } }
    );

    return data;
  };

  export const joinChannel = async (body: JoinLeaveChannelRequest) => {
    await client.post(`${BASE_PREFIX}/join`, body);
  };

  export const leaveChannel = async (body: JoinLeaveChannelRequest) => {
    await client.post(`${BASE_PREFIX}/leave`, body);
  };
}
