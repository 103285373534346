import React from "react";
import UpdateProfileContainer from "#containers/UpdateProfile";
import LoginTitle from "#components/common/LoginComponents/LoginTitle";

export default function UpdateProfile() {
  return (
    <>
      <LoginTitle prefixIntl={"pages.profile_update"} />
      <UpdateProfileContainer />
    </>
  );
}
