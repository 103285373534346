import React from "react";

// apis
import MuiCookieConsent from "#components/common/MuiCookieConsent/MuiCookieConsent";
import env from "#env";
import useAnalytics from "../../utils/useAnalytics";
import useRedirect from "#components/routing/useRedirect";

type PrivateRouteProps = {
  children: React.ReactElement;
};

export default function RedirectRoute({ children }: PrivateRouteProps) {
  const checkingCredentials = useRedirect();

  const setCookieConsentChoice = useAnalytics(env.VITE_GOOGLE_ANALYTICS_TRACKING_ID);
  const onAcceptOrRejectCookieConsent = (consent: boolean | undefined) => {
    setCookieConsentChoice(consent);
    localStorage.setItem("cookieConsent", consent + "");
  };

  if (checkingCredentials) return null;

  return (
    <>
      <MuiCookieConsent onAcceptOrRejectCookieConsent={onAcceptOrRejectCookieConsent} />
      {children}
    </>
  );
}
