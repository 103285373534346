import { BuilderLaunchFormFields } from "#components/pages/BriefBuilder/Launch/hooks/useBuilderLaunchForm";
import {
  BuilderNotificationFormType,
  BuilderNotificationChannel,
} from "#components/pages/BriefBuilder/types";
import {
  BriefBuilderData,
  BuilderBaseNotificationType,
  BuilderLaunchNotificationType,
  BuilderNotificationStatus,
  BuilderScheduleNotificationType,
  SaveBriefLaunchRequest,
} from "#customTypes/briefBuilder";
import dayjs from "dayjs";

const DEFAULT_EMAIL_NOTIFICATION_SUBJECT = "We have something for you!";
const DEFAULT_EMAIL_NOTIFICATION_BODY = `{channel_name} is thrilled to invite you to participate in our upcoming "{brief_name}" brief! This is a unique chance to dive deep, contribute your insights, and connect with like-minded individuals.\n\nSimply click on the button below to enrol in the brief. We're eager to welcome you into this vibrant community and explore new horizons together.\n\nWe can’t wait to see you there and celebrate your contributions!`;
const DEFAULT_PUSH_NOTIFICATION_MESSAGE =
  "Exciting news! We got a new brief for you. Check it out!";

export const BUILDER_DEFAULT_EMAIL_NOTIFICATION = {
  subject: DEFAULT_EMAIL_NOTIFICATION_SUBJECT,
  body: DEFAULT_EMAIL_NOTIFICATION_BODY,
};

export const BUILDER_DEFAULT_PUSH_NOTIFICATION = {
  message: DEFAULT_PUSH_NOTIFICATION_MESSAGE,
};

export const getBriefDefaultScheduleDate = () => {
  return dayjs().add(7, "days").toISOString();
};

export const getBriefDefaultEndDate = (startDate: string) => {
  const endsDate = dayjs(startDate).add(1, "month");
  return endsDate.toISOString();
};

export const mapBuilderLaunchFormFields = (
  brief?: BriefBuilderData
): BuilderLaunchFormFields => {
  return {
    method:
      !brief?.startsOn || dayjs(brief.startsOn).isBefore(dayjs()) ? "now" : "schedule",
    startsOn: brief?.startsOn ?? "",
    endsOn: brief?.endsOn ?? "",
    launchNotification: brief?.launchNotification
      ? mapBuilderNotificationFormFields(brief?.launchNotification, "launch")
      : null,
    notifications:
      brief?.notifications?.map((notification) =>
        mapBuilderNotificationFormFields(notification)
      ) || [],
  };
};

export const mapBuilderNotificationFormFields = (
  notification?: BuilderScheduleNotificationType | BuilderLaunchNotificationType | null,
  type: "launch" | "schedule" = "schedule"
): BuilderNotificationFormType => {
  if (!notification) {
    return mapNotificationFormFields(type);
  }

  if (type === "launch" && "enabled" in notification) {
    return mapNotificationFormFields("launch", notification);
  }

  return mapNotificationFormFields("schedule", notification);
};

export const getDefaultNotificationFormFields = (
  type: "launch" | "schedule" = "schedule",
  startDate?: string,
  id?: number
): BuilderNotificationFormType => ({
  id: id ?? 0,
  added: !!id,
  channels: [BuilderNotificationChannel.Email],
  status: BuilderNotificationStatus.Scheduled,
  emailSubject: DEFAULT_EMAIL_NOTIFICATION_SUBJECT,
  emailBody: DEFAULT_EMAIL_NOTIFICATION_BODY,
  pushContent: DEFAULT_PUSH_NOTIFICATION_MESSAGE,
  launchEnabled: false,
  scheduledOn: type === "launch" ? "" : dayjs(startDate).add(5, "day").toISOString(),
  sentOn: "",
  skipUsersResponded: true,
});

const mapNotificationFormFields = (
  type: "launch" | "schedule",
  notification?: BuilderScheduleNotificationType | BuilderLaunchNotificationType | null
): BuilderNotificationFormType => {
  const defaultNotification = getDefaultNotificationFormFields(type);

  if (!notification) {
    return defaultNotification;
  }

  if (type === "launch" && "enabled" in notification) {
    const launchNotification = notification as BuilderLaunchNotificationType;

    return {
      ...defaultNotification,
      launchEnabled: launchNotification.enabled ?? defaultNotification.launchEnabled,
      channels: mapBuilderNotificationChannels(launchNotification),
      status: launchNotification.status ?? defaultNotification.status,
      pushContent: launchNotification.push?.message ?? defaultNotification.pushContent,
      emailSubject: launchNotification.email?.subject ?? defaultNotification.emailSubject,
      emailBody: launchNotification.email?.body ?? defaultNotification.emailBody,
      scheduledOn: defaultNotification.scheduledOn ?? defaultNotification.scheduledOn,
      sentOn: launchNotification.execEnd ?? defaultNotification.sentOn,
    };
  }

  const scheduleNotification = notification as BuilderScheduleNotificationType;

  return {
    ...defaultNotification,
    id: scheduleNotification.id ?? defaultNotification.id,
    channels: mapBuilderNotificationChannels(scheduleNotification),
    status: scheduleNotification.status ?? defaultNotification.status,
    pushContent: scheduleNotification.push?.message ?? defaultNotification.pushContent,
    emailSubject: scheduleNotification.email?.subject ?? defaultNotification.emailSubject,
    emailBody: scheduleNotification.email?.body ?? defaultNotification.emailBody,
    scheduledOn: scheduleNotification.scheduled ?? defaultNotification.scheduledOn,
    sentOn: scheduleNotification.execEnd ?? defaultNotification.sentOn,
    skipUsersResponded:
      scheduleNotification.skipUsersResponded ?? defaultNotification.skipUsersResponded,
  };
};

export const mapBuilderLaunchRequest = (
  briefRef: string,
  data: BuilderLaunchFormFields
): SaveBriefLaunchRequest => {
  const launchNotification = data.launchNotification
    ? {
        enabled: data.launchNotification.launchEnabled,
        status: data.launchNotification.status,
        push: data.launchNotification.channels.includes(BuilderNotificationChannel.Push)
          ? { message: data.launchNotification.pushContent }
          : undefined,
        email: data.launchNotification.channels.includes(BuilderNotificationChannel.Email)
          ? {
              subject: data.launchNotification.emailSubject,
              body: data.launchNotification.emailBody,
            }
          : undefined,
      }
    : null;

  return {
    briefRef,
    startsOn: data.startsOn || dayjs().set("ms", 0).toISOString(),
    endsOn: data.endsOn || null,
    launchNotification,
    notifications: data.notifications.map((notification) => ({
      id: !notification.added ? notification.id : undefined,
      status: notification.status,
      scheduled: notification.scheduledOn,
      push: notification.channels.includes(BuilderNotificationChannel.Push)
        ? { message: notification.pushContent }
        : undefined,
      email: notification.channels.includes(BuilderNotificationChannel.Email)
        ? { subject: notification.emailSubject, body: notification.emailBody }
        : undefined,
      skipUsersResponded: notification.skipUsersResponded,
    })),
  };
};

const mapBuilderNotificationChannels = (notification: BuilderBaseNotificationType) => {
  const channels = [] as BuilderNotificationChannel[];
  if (notification.push) {
    channels.push(BuilderNotificationChannel.Push);
  }
  if (notification.email) {
    channels.push(BuilderNotificationChannel.Email);
  }
  return channels;
};
