import React from "react";

// components
import CustomModal from "#components/common/Modal";
import useModal from "#components/common/Modal/useModal";
import DownloadContainer from "#containers/Download";
import ProfileIcons from "#components/common/layout/Header/HeaderIcons/ProfileIcons";
import LoginIcons from "#components/common/layout/Header/HeaderIcons/LoginIcons";
import { useIsMobileApp } from "#utils/useIsMobileApp";

type HeaderIcons = {
  isProtectedPage: boolean;
  isSigningPage: boolean;
  onSelectLocale: (locale: string) => void;
};

export default function HeaderIcons({
  isProtectedPage,
  isSigningPage,
  onSelectLocale,
}: HeaderIcons) {
  const [modal, setModal] = useModal({
    Content: <DownloadContainer />,
  });

  const { isMobileApp } = useIsMobileApp();
  const onClickIcon = () =>
    setModal({
      open: true,
    });
  return (
    <>
      <div className="icons" style={isMobileApp ? { display: "hidden" } : undefined}>
        {isProtectedPage ? (
          <ProfileIcons onClickIcon={onClickIcon} />
        ) : (
          <LoginIcons
            shouldRenderLanguageMenu={isSigningPage}
            onSelectLocale={onSelectLocale}
          />
        )}
      </div>

      <CustomModal
        open={modal.open || false}
        onClose={() =>
          setModal({
            open: false,
          })
        }
        Content={modal.Content}
      />
    </>
  );
}
