import React from "react";
import { FormattedMessage } from "react-intl";
import { Divider, styled } from "@mui/material";
import { Organisation } from "../../../../types/db";
import { getIsWlaApp, getOrganisationKeyValue } from "../../../../utils/organisation";
import { EMAIL_FALLBACKS, LINK_FALLBACKS } from "#constants/common";

const MetaWrapper = styled("div")(
  ({ theme }) => `
  left: auto;
  background-color: white;
  font-size: 0.75rem;
  color: #8e8e8e;
  

  ${theme.breakpoints.down("md")} {
    font-size: 0.875rem;
    line-height: 1.5rem;
    gap: 0.675rem 0.25rem;
  }

  a {
    color: #8e8e8e;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }

    &:not(:last-child) {
      &::after {
        content: "·";
        margin-left: 0.25rem;
      }
    }
    
  }

  div {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
`
);

const LinksWrapper = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.25rem;

  ${theme.breakpoints.down("md")} {
    font-size: 0.875rem;
    line-height: 1.5rem;
    gap: 0.675rem 0.25rem;
  }

  a {
    color: #8e8e8e;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }

    &:not(:last-child) {
      &::after {
        content: "·";
        margin-left: 0.25rem;
      }
    }
  }
`
);

type OrganisationLinkKeys = keyof Pick<
  Organisation,
  "supportURL" | "privacyPolicy" | "termsOfUse" | "supportEmail"
>;

interface Link {
  id: string;
  href: string;
  target?: React.AnchorHTMLAttributes<HTMLAnchorElement>["target"];
  linkModifier?(emailAddress: string): string;
  organisationKey?: OrganisationLinkKeys;
  renderOnWLA?: boolean;
}

const links: Link[] = [
  {
    id: "about-us",
    href: LINK_FALLBACKS.about,
    organisationKey: "supportURL",
    target: "_blank",
    renderOnWLA: true,
  },
  { id: "help", href: LINK_FALLBACKS.faq },
  {
    id: "privacy",
    href: LINK_FALLBACKS.privacy,
    organisationKey: "privacyPolicy",
    target: "_blank",
    renderOnWLA: true,
  },
  {
    id: "terms-of-us",
    href: LINK_FALLBACKS.terms,
    organisationKey: "termsOfUse",
    target: "_blank",
    renderOnWLA: true,
  },
  { id: "blog", href: LINK_FALLBACKS.blog, target: "_blank" },
  { id: "careers", href: LINK_FALLBACKS.careers, target: "_blank" },
  {
    id: "contact-us",
    href: EMAIL_FALLBACKS.support,
    linkModifier: (emailAddress: string) =>
      `mailto:${emailAddress}?subject=My.Bulbshare.com Support`,
    organisationKey: "supportEmail",
    renderOnWLA: true,
  },
];

interface Props {
  domainOrganisation?: string;
  organisation?: Organisation;
  showDivider?: boolean;
}

const MenuLinks = (props: Props) => {
  const isWla = getIsWlaApp(props.domainOrganisation);

  const linksToRender = isWla ? links.filter((l) => l.renderOnWLA) : links;

  return (
    <MetaWrapper>
      <LinksWrapper>
        {linksToRender.map(({ id, href, organisationKey, target, linkModifier }) => {
          const keyValue = getOrganisationKeyValue(props.organisation, organisationKey);
          const value = linkModifier ? linkModifier(keyValue || href) : keyValue || href;

          return (
            <a key={id} href={value} rel="noreferrer" target={target}>
              <FormattedMessage id={`pages.feed.menu_links.${id}`} />
            </a>
          );
        })}
      </LinksWrapper>
      {props.showDivider && <Divider className="menu-divider" />}
      <div className="copyright">
        <a target="_blank" href="https://bulbshare.com" rel="noreferrer">
          © {new Date().getFullYear()}{" "}
          <FormattedMessage
            id={
              isWla
                ? "pages.feed.menu_links.copyright_powered_by"
                : "pages.feed.menu_links.copyright_default"
            }
          />
        </a>
      </div>
    </MetaWrapper>
  );
};

export default MenuLinks;
