import React, { ReactNode } from "react";
import {
  AIChatMessageMetadata,
  AIChatMessageProps,
  AIChatRole,
} from "#components/common/AIChat/types";
import colors from "#shared/config/theme/colors";
import rounding from "#shared/config/theme/rounding";
import useTranslation from "#utils/useTranslation";
import { Box, styled, Typography } from "@mui/material";
import AIChatMessageLoading from "#components/common/AIChat/components/message/AIChatMessageLoading";
import { formatAIChatMessage } from "#components/common/AIChat/utils";
import classNames from "classnames";
import Markdown from "markdown-to-jsx";
interface Props extends AIChatMessageProps {
  HeaderActions?: ReactNode;
  children?: ReactNode;
  metadata?: AIChatMessageMetadata;
  className?: string;
}

export default function AIChatMessageContent({
  role,
  content,
  loading,
  metadata,
  className,
  HeaderActions,
  children,
}: Props) {
  const { t } = useTranslation();

  const roleLabel = () => {
    switch (role) {
      case AIChatRole.User:
        return t("ui.ai_chat.roles.user");
      case AIChatRole.Assistant:
        return t("ui.ai_chat.roles.assistant");
      default:
        return "";
    }
  };

  const renderContent = () => {
    if (loading) {
      return <AIChatMessageLoading />;
    }

    return (
      <Box>
        <MessageWrapper>
          <Typography variant="body4" className="chat-message-content" component="div">
            <Markdown>{formatAIChatMessage(content, metadata)}</Markdown>
          </Typography>
        </MessageWrapper>
        {children && <Box mt={2}>{children}</Box>}
      </Box>
    );
  };

  return (
    <MessageContent className={classNames("chat-message-content", className)}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography
          variant="label3"
          fontFamily="Avenir-heavy"
          className="chat-message-role"
        >
          {roleLabel()}
        </Typography>
        {HeaderActions && <Box>{HeaderActions}</Box>}
      </Box>
      {renderContent()}
    </MessageContent>
  );
}

const MessageContent = styled(Box)`
  max-width: calc(100% - 128px);
  border-radius: ${rounding.default};
  background-color: ${colors.grey[50]};
  color: ${colors.text.secondaryDark};
  padding: 1rem;
  opacity: 0;
  animation: message 1s forwards;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-width: calc(100% - 112px);
  }

  &.-full-width {
    flex: 1;
  }

  &.-error-message {
    background-color: ${colors.error[50]};
  }

  @keyframes message {
    0% {
      opacity: 0;
      transform: translateY(10px);
      scale: 0.95;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
      scale: 1;
    }
  }
`;

const MessageWrapper = styled("div")`
  .chat-message-content > div {
    & > *:first-of-type {
      margin-top: 0;
    }

    & > *:last-of-type {
      margin-bottom: 0;
    }

    p {
      margin: 0.5rem 0;
    }

    ul,
    ol {
      padding-left: 1rem;
      margin-top: 0;
      margin-bottom: 0.5rem;
    }
  }
`;
