import { SearchOrganisationsRequest } from "#customTypes/admin";
import { superAdminApi } from "#features/superAdmin/superAdminAPI";
import { useInfiniteQuery } from "#utils/useInfiniteQuery";

const PAGE_SIZE = 100;

export default function useSearchOrganisations(
  params: Omit<SearchOrganisationsRequest, "page" | "pageSize" | "isPublicUser">
) {
  return useInfiniteQuery(superAdminApi.endpoints.searchOrganisations)(params, {
    pageSize: PAGE_SIZE,
  });
}
