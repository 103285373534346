import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { toRem } from "#shared/utils";

const WrapperPage = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: flex-start;
`;

const WrapperContent = styled("div")<{ fullWidth?: boolean }>(
  ({ theme, fullWidth }) => `
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: inherit;
  width: var(--container-width);
  box-sizing: border-box;
  margin-top: 1.25rem;

  display: grid;
  grid-template-columns: ${toRem(268)} 1fr ${toRem(280)};
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  ${theme.breakpoints.between("md", "lg")} {
    grid-template-columns: ${toRem(268)} 1fr;
  }


  ${theme.breakpoints.down("md")} {
    display: flex;
    margin-top: ${!fullWidth ? "1rem" : "0"};
    padding: ${!fullWidth ? "0 1rem" : "0"};
  }
`
);

const WrapperPageContent = styled("div")(
  ({ theme }) => `
  max-width: 29.375rem;
  margin: auto;
  width: 100%;
  margin-top: 1.25rem;
  padding-bottom: 2rem;

  ${theme.breakpoints.down("md")} {
    margin-top: 0rem;
    padding-bottom: 1rem;
  }
`
);

const WrapperGetTheApp = styled(Button)(
  ({ theme }) => `
    position: fixed;
    right: 2.125rem;
    z-index: 1;
    bottom: 0.688rem;
    ${theme.breakpoints.down("sm")} {
       display: none;
    }
`
);

// TODO: deprecated
const WrapperItems = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  max-width: 29.375rem;
  margin: 0 auto 1rem;
`;

export {
  WrapperGetTheApp,
  WrapperItems,
  WrapperPage,
  WrapperContent,
  WrapperPageContent,
};
