import React from "react";

import { CustomRoute } from "#shared/types";

import WrapperPrivateRoute from "./private/WrapperPrivateRoute";
import RedirectRoute from "./RedirectRoute";
import LazyPage from "#components/common/LazyImport/LazyView";
import { NavigationAction } from "#constants/navigation";
import Authentication from "#components/pages/Login/Authentication";
import EmailSent from "#components/pages/Login/EmailSent";
import UpdateProfile from "#components/pages/Login/UpdateProfile";
import WrapperBusinessCentreRoute from "#components/routing/business/WrapperBusinessCentreRoute";
import { Navigate } from "react-router";

import WrapperChannelRoute from "#components/routing/channel/WrapperChannelRoute";
import WrapperBriefBuilderRoute from "#components/routing/briefBuilder/WrapperBriefBuilderRoute";

/* needs to be imported here,
  importing in the Messages breaks loading brief details in Safari on production build */
import "../../components/pages/BriefDetails/BriefComments/Messages/messageStyles.scss";

const LazyAuthenticated = LazyPage({ page: "Authenticated" });
const LazyScreenerSuccess = LazyPage({ page: "ScreenerSuccess" });
const LazyLetsBeFriends = LazyPage({ page: "LetsBeFriends" });
const LazyDownload = LazyPage({ page: "Download" });
const LazyNotFound = LazyPage({ page: "NotFound" });
const LazyLogin = LazyPage({ page: "Login" });
const LazyBrief = LazyPage({ page: "PublicBrief" });
const LazyFeed = LazyPage({ page: "Feed" });
const LazyScreener = LazyPage({ page: "Screener" });
const LazySurvey = LazyPage({ page: "Survey" });
const LazySurveyPreview = LazyPage({ page: "SurveyPreview" });
const LazyRedirectEmailUser = LazyPage({ page: "RedirectEmailUser" });
const LazyRedirectProviderUser = LazyPage({ page: "RedirectProviderUser" });
const LazyError = LazyPage({ page: "Error" });
const LazyEmailSent = LazyPage({ page: "EmailSent" });
const LazyBusinessLab = LazyPage({ page: "BusinessLab" });
const LazyBusinessAccount = LazyPage({ page: "BusinessAccount" });
const LazyTeamManagement = LazyPage({ page: "TeamManagement" });
const LazyBusinessSettings = LazyPage({ page: "BusinessSettings" });
const LazyChannelSettings = LazyPage({ page: "ChannelSettings" });
const LazyChannelCommunity = LazyPage({ page: "ChannelCommunity" });
const LazyInvite = LazyPage({ page: "Invite" });
const LazyIntegrations = LazyPage({ page: "Integrations" });
const LazyTiptapPlayground = LazyPage({ page: "TiptapPlayground" });
const LazyBriefBuilderBrief = LazyPage({ page: "BriefBuilderBrief" });
const LazyBriefBuilderGeneral = LazyPage({ page: "BriefBuilderGeneral" });
const LazyBriefBuilderAudience = LazyPage({ page: "BriefBuilderAudience" });
const LazyBriefBuilderLaunch = LazyPage({ page: "BriefBuilderLaunch" });
const LazyBriefBuilderSurvey = LazyPage({ page: "BriefBuilderSurvey" });
const LazyBriefBuilderShare = LazyPage({ page: "BriefBuilderShare" });
const LazyGodViewRedirect = LazyPage({ page: "GodViewRedirect" });
const LazyBriefBuilderGenerate = LazyPage({ page: "BriefBuilderGenerate" });

export default function AllRoutes(): CustomRoute[] {
  return [
    {
      path: "*",
      element: (
        <RedirectRoute>
          <WrapperPrivateRoute />
        </RedirectRoute>
      ),
      children: [
        { path: "authenticated", element: <LazyAuthenticated />, page: "Authenticated" },
        {
          path: "screener/success/:briefRef",
          element: <LazyScreenerSuccess />,
          page: "ScreenerSuccess",
        },
        {
          path: "lets-be-friends/:briefRef",
          element: <LazyLetsBeFriends />,
          page: "LetsBeFriends",
        },
        {
          path: "download",
          element: <LazyDownload />,
          page: "Download",
        },
        {
          path: "email-sent",
          element: <LazyEmailSent />,
          page: "EmailSent",
        },
        {
          path: "survey/preview/:briefRef",
          element: <LazySurveyPreview />,
          page: "SurveyPreview",
        },
        {
          path: NavigationAction.businesslab,
          element: (
            <React.Suspense>
              <LazyBusinessLab />
            </React.Suspense>
          ),
          page: "BusinessLab",
        },
        ...["business-centre", "business-centre/:organisationId"].map((path) => ({
          path,
          element: (
            <React.Suspense>
              <WrapperBusinessCentreRoute />
            </React.Suspense>
          ),
          children: [
            {
              path: "",
              element: <LazyBusinessAccount />,
              page: "BusinessAccount",
            },
            {
              path: "team-management",
              element: <LazyTeamManagement />,
              page: "TeamManagement",
            },
            {
              path: "settings",
              element: <LazyBusinessSettings />,
              page: "BusinessSettings",
            },
            {
              path: "integrations",
              element: <LazyIntegrations />,
              page: "Integrations",
            },
            {
              path: "*",
              element: <Navigate to="/business-centre" replace />,
              page: "NotFound",
            },
          ],
        })),
        ...[
          "",
          "feed",
          "feed/:briefRef",
          NavigationAction.foryou,
          NavigationAction.following,
        ].map((path) => ({
          path,
          element: (
            <React.Suspense>
              <LazyFeed />
            </React.Suspense>
          ),
          page: "Feed",
        })),
        {
          path: "channel/:channelRef",
          element: (
            <React.Suspense>
              <WrapperChannelRoute />
            </React.Suspense>
          ),
          children: [
            {
              path: "",
              element: <LazyFeed />,
              page: "Feed",
            },
            {
              path: "settings",
              element: <LazyChannelSettings />,
              page: "ChannelSettings",
            },
            {
              path: "community",
              element: <LazyChannelCommunity />,
              page: "ChannelCommunity",
            },
          ],
        },
        {
          path: "brief-builder/:briefRef",
          element: <WrapperBriefBuilderRoute />,
          children: [
            {
              path: "brief",
              element: <LazyBriefBuilderBrief />,
              page: "BriefBuilderBrief",
            },
            {
              path: "brief/content",
              element: <LazyBriefBuilderBrief />,
              page: "BriefBuilderBriefContent",
            },
            {
              path: "general",
              element: <LazyBriefBuilderGeneral />,
              page: "BriefBuilderGeneral",
            },
            {
              path: "audience",
              element: <LazyBriefBuilderAudience />,
              page: "BriefBuilderAudience",
            },
            {
              path: "launch",
              element: <LazyBriefBuilderLaunch />,
              page: "BriefBuilderLaunch",
            },
            {
              path: "survey",
              element: <LazyBriefBuilderSurvey />,
              page: "BriefBuilderSurvey",
            },
            {
              path: "share",
              element: <LazyBriefBuilderShare />,
              page: "BriefBuilderShare",
            },
            {
              path: "*",
              element: <Navigate to="/404" replace />,
            },
          ],
        },
        {
          path: "brief-builder/generate/:id",
          element: <LazyBriefBuilderGenerate />,
          page: "BriefBuilderGenerate",
        },
        {
          path: "brief-builder",
          element: <Navigate to="/404" replace />,
        },

        {
          path: "*",
          element: <LazyNotFound />,
          page: "NotFound",
        },
      ],
    },
    {
      path: "signin",
      element: (
        <RedirectRoute>
          <LazyLogin />
        </RedirectRoute>
      ),
      children: [
        {
          path: "",
          element: <Authentication />,
        },
        {
          path: "email",
          element: <EmailSent />,
          page: "EmailSent",
        },
        {
          path: "profile",
          element: <UpdateProfile />,
        },
      ],
    },
    {
      path: "/survey/:briefRef",
      element: <LazySurvey />,
      page: "Survey",
    },
    {
      path: "/survey/preview/:briefRef",
      element: <LazySurveyPreview />,
      page: "SurveyPreview",
    },
    {
      path: "/continue/:token",
      element: <LazyRedirectEmailUser />,
      page: "RedirectEmailUser",
    },
    {
      path: "/redirect-provider-user",
      element: <LazyRedirectProviderUser />,
      page: "RedirectProviderUser",
    },
    {
      path: "/brief/:briefRef",
      element: <LazyBrief />,
      page: "PublicBrief",
    },
    {
      path: "god-view-redirect",
      element: <LazyGodViewRedirect />,
      page: "GodViewRedirect",
    },
    {
      path: "/screener/:briefId",
      element: (
        <RedirectRoute>
          <LazyScreener />
        </RedirectRoute>
      ),
      page: "Screener",
    },
    {
      path: "invite/:token",
      element: <LazyInvite />,
      page: "Invite",
    },
    {
      path: "tiptap-playground",
      element: <LazyTiptapPlayground />,
      page: "TiptapPlayground",
    },
    {
      path: "/error",
      element: <LazyError />,
      page: "Error",
    },
  ];
}
