import logout from "#apis/user/logout";
import { setDownloadModal } from "#features/downloadModal/downloadModalSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { LINK_FALLBACKS } from "#constants/common";
import { NavigationAction } from "#constants/navigation";
import { getOrganisationDomain, useGetWlaOrganisation } from "#utils/organisation";
import { GaEvents, gaTrackEvent } from "#utils/useGA";
import useGodView from "#containers/GodView/useGodView";
import { removeGodView } from "#features/superAdmin/godViewSlice";

export default function useNavigationAction() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { openModal: openGodViewModal } = useGodView();

  const domainOrganisation = getOrganisationDomain(window.location.href);
  const { organisation } = useGetWlaOrganisation(domainOrganisation);

  const checkActionForGA = (action: NavigationAction) => {
    switch (action) {
      case NavigationAction.profile:
        gaTrackEvent(GaEvents.openeditprofilebuttonpress, "", "");
        break;
      case NavigationAction.settings:
        gaTrackEvent(GaEvents.opensettingsbuttonpress, "", "");
        break;
      case NavigationAction.businesslab:
        gaTrackEvent(GaEvents.business_lab_access, "", "");
        break;
      case NavigationAction.knowledgeAi:
        gaTrackEvent(GaEvents.knowledge_ai_access, "", "");
        break;
    }
  };

  const navigateAction = useCallback(
    async (action: NavigationAction) => {
      checkActionForGA(action);
      if (action === NavigationAction.logout) {
        await logout();
        navigate("/signin");
        dispatch(removeGodView());
        return;
      }

      if (action === NavigationAction.feedback_help) {
        window.open(organisation?.supportURL || LINK_FALLBACKS.faq, "_blank");
        return;
      }

      if (action.startsWith("#getApp")) {
        dispatch(setDownloadModal(true));
        return;
      }

      if (action === NavigationAction.god_view) {
        openGodViewModal();
        return;
      }

      if (action.startsWith("https://")) {
        window.open(action, "_blank");
        return;
      }

      navigate(`/${action}`, { state: { navigated: true } });
      window.scroll({ top: 0 });
    },
    [organisation]
  );

  return { navigateAction };
}
