import { Box } from "@mui/material";
import { LoadingButton } from "#shared/components/ui";
import useIntersectObserver from "#shared/utils/hooks/useIntersectObserver";
import useTranslation from "#utils/useTranslation";
import React, { ReactNode, useEffect, useState } from "react";

interface Props {
  offset?: number;
  autoLoad?: boolean;
  delay?: number;
  hasNextPage: boolean;
  isLoadingNextPage: boolean;
  loadNextPage: () => void;
  children: ReactNode;
}

function InfiniteLoader({
  offset = 100,
  autoLoad = false,
  delay = 500,
  hasNextPage,
  isLoadingNextPage,
  loadNextPage,
  children,
}: Props) {
  const { t } = useTranslation();
  const [loadMoreRef, entryBottom] = useIntersectObserver<HTMLDivElement | null>({});

  const [isFirstLoad, setIsFirstLoad] = useState(!autoLoad);
  const [loadingDelayPassed, setLoadingDelayPassed] = useState(true);

  useEffect(() => {
    if (
      entryBottom?.isIntersecting &&
      hasNextPage &&
      !isFirstLoad &&
      loadingDelayPassed
    ) {
      loadNextPage();
      setLoadingDelayPassed(false);

      setTimeout(() => {
        setLoadingDelayPassed(true);
      }, delay);
    }
  }, [entryBottom?.isIntersecting, hasNextPage, loadNextPage, loadingDelayPassed, delay]);

  const handleLoadNextPageClick = () => {
    loadNextPage();

    // Add slight delay to prevent two pages loading one after the another
    setTimeout(() => {
      setIsFirstLoad(false);
    }, 500);
  };

  return (
    <Box className="infinite-loader" sx={{ position: "relative" }}>
      {children}
      <Box
        sx={{ position: "absolute", bottom: 0, left: 0, height: `${offset}px` }}
        ref={loadMoreRef}
      />
      {hasNextPage && (
        <Box display="flex" justifyContent="center">
          {hasNextPage && (
            <LoadingButton
              variant="cta"
              size="small"
              sx={{ mt: 4 }}
              loading={isLoadingNextPage}
              onClick={handleLoadNextPageClick}
            >
              {t("ui.buttons.load_more")}
            </LoadingButton>
          )}
        </Box>
      )}
    </Box>
  );
}

export default InfiniteLoader;
