import { useGlobalAppProvider } from "#containers/Global/GlobalAppProvider";
import { ChannelApprovalStatus, ChannelListItem } from "#customTypes/channel";
import { OrganisationPositionType } from "#customTypes/types";
import { useFetchMyOwnedChannelsQuery } from "#features/channel/channelsAPI";
import { getOrganisationDomain, useGetWlaOrganisation } from "#utils/organisation";
import { useCallback, useMemo } from "react";

export default function useMyOwnedChannels() {
  const domainOrganisation = getOrganisationDomain(window.location.href);
  const { organisation } = useGetWlaOrganisation(domainOrganisation);

  const { positions } = useGlobalAppProvider();

  const query = useFetchMyOwnedChannelsQuery({
    organisationId: organisation?.organisationId,
  });

  const filterOwnedChannel = useCallback(
    (channel: ChannelListItem) => {
      if (!channel.isVerified) {
        return true;
      }

      return positions.some(
        (position) =>
          position.organisation.id === channel.organisationId &&
          [OrganisationPositionType.Owner, OrganisationPositionType.Admin].includes(
            position.id
          )
      );
    },
    [positions]
  );

  const approvedChannels = useMemo(() => {
    return query.data?.filter(
      (channel) =>
        channel.approvedStatus === ChannelApprovalStatus.APPROVED &&
        filterOwnedChannel(channel)
    );
  }, [query.data, filterOwnedChannel]);

  const approvedBusinessChannels = useMemo(() => {
    return query.data?.filter(
      (channel) =>
        channel.approvedStatus === ChannelApprovalStatus.APPROVED &&
        channel.isVerified &&
        filterOwnedChannel(channel)
    );
  }, [query.data, filterOwnedChannel]);

  return {
    ...query,
    channels: query.data ?? [],
    approvedChannels: approvedChannels ?? [],
    approvedBusinessChannels: approvedBusinessChannels ?? [],
    hasOwnedChannels: !!query.data?.length,
  };
}
