import useMergeState from "#shared/utils/hooks/useMergeState";
import { UseModalProps } from "../../../types/types";

export default function useModal({
  open = false,
  Content,
}: UseModalProps): [UseModalProps, (props: UseModalProps) => void] {
  const [modalDetails, setModalDetails] = useMergeState<UseModalProps>({
    open,
    Content,
  });
  return [modalDetails, setModalDetails];
}
