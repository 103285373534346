import React, { useState } from "react";
import useMyOwnedChannels from "#components/pages/Feed/ChannelsSidebar/useMyOwnedChannels";
import { ChannelListItem } from "#customTypes/channel";
import { BaseModalProps, ModalCloseReason } from "#customTypes/modals";
import { IMAGE_TYPES } from "#customTypes/types";
import SelectCard from "#shared/components/ui/SelectCard";
import getResourcesURL from "#utils/getResourcesURL";
import { Box, Typography } from "@mui/material";
import DefaultChannelImage from "#assets/svgs/default_channel.svg";
import Avatar from "#components/common/Avatar";
import colors from "#shared/config/theme/colors";
import ConfirmDialog from "#components/common/Dialog/ConfirmDialog";
import useTranslation from "#utils/useTranslation";

interface Props extends BaseModalProps {
  onSelectChannel: (channel: ChannelListItem) => void;
}

export default function SelectChannelModal({ isOpen, onSelectChannel, onClose }: Props) {
  const { t } = useTranslation();
  const { approvedChannels } = useMyOwnedChannels();

  const [selected, setSelected] = useState<ChannelListItem | null>(null);

  const handleConfirm = () => {
    if (!selected) {
      return;
    }

    setSelected(null);
    onSelectChannel(selected);
  };

  const handleClose = () => {
    setSelected(null);
    onClose(ModalCloseReason.Dismiss);
  };

  const selectItem = (channel: ChannelListItem) => {
    const logoSrc = getResourcesURL(channel.logo, IMAGE_TYPES.CHANNEL_LOGO);

    return (
      <SelectCard
        selected={selected?.id === channel.id}
        onClick={() => setSelected(channel)}
      >
        <Box display="flex" alignItems="center" gap={[2, 4]}>
          <Box display="flex" flex="1" pr={4}>
            <Avatar
              uri={logoSrc || DefaultChannelImage}
              diameter={2.5}
              className="circle"
            />
            <Box ml={4}>
              <Typography variant="body4" fontFamily="Avenir-heavy" mb={0.5}>
                {channel.name}
              </Typography>
              <Typography variant="label3" color={colors.text.secondaryDark}>
                {channel.isVerified
                  ? channel.organisationName
                  : t("pages.feed.filters.personal")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </SelectCard>
    );
  };

  return (
    <ConfirmDialog
      isOpen={isOpen}
      onClose={handleClose}
      title={t("pages.brief_builder.modals.select_channel.title")}
      content={null}
      confirmButtonText={t("pages.brief_builder.modals.select_channel.select")}
      confirmButtonDisabled={!selected}
      onConfirm={handleConfirm}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        {approvedChannels.map((channel) => (
          <Box key={channel.id}>{selectItem(channel)}</Box>
        ))}
      </Box>
    </ConfirmDialog>
  );
}
