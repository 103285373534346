import env from "#env";

import { store as rootStore } from "../store";
import { Organisation } from "#customTypes/db";
import { fetchWlaOrganisation, getOrganisationDomain } from "#utils/organisation";

class APIHeaders {
  private origin: string;

  constructor() {
    this.origin = this.parseFullUrl(env.VITE_BASE_URL_UI);
  }

  private parseFullUrl = (fullUrl: string) =>
    fullUrl.replace(/(https?:\/\/|:3000|local\.)/g, "").replace(/\/.*/, "");

  private getWLAParam = (data?: Organisation) =>
    data?.isWla && data.organisationId
      ? { WLA: data.organisationId.toString() }
      : undefined;

  private getSourceDomainParam = (data?: Organisation) =>
    data?.domain ? { SourceDomain: this.origin.replace("my", data.domain) } : undefined;

  public getWlaHeaders = async (): Promise<HeadersInit> => {
    const domainOrganisation = getOrganisationDomain(window.location.href);
    const organisation = await fetchWlaOrganisation(rootStore, domainOrganisation);

    return {
      ...this.getWLAParam(organisation),
      ...this.getSourceDomainParam(organisation),
    };
  };
}

export const apiHeaders = new APIHeaders();
