import React from "react";

import { FormattedMessage } from "react-intl";
import LoginFootnoteWrapper from "../../../common/LoginComponents/LoginFootnoteWrapper";
import { AvenirAndHeavyText } from "../../../common/styled";
import {
  getOrganisationDomain,
  useGetWlaOrganisation,
} from "../../../../utils/organisation";

import { LINK_FALLBACKS } from "#constants/common";

export default function FooterTextContent() {
  const domainOrganisation = getOrganisationDomain(window.location.href);
  const { organisation } = useGetWlaOrganisation(domainOrganisation);

  return (
    <LoginFootnoteWrapper>
      <AvenirAndHeavyText fontSize="inherit" lineHeight="inherit">
        <FormattedMessage
          id="pages.login.content.eula_warning"
          values={{
            tos: (
              <a
                href={organisation?.termsOfUse || LINK_FALLBACKS.terms}
                target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage id="footer.terms.title" />
              </a>
            ),
            pp: (
              <a
                href={organisation?.privacyPolicy || LINK_FALLBACKS.privacy}
                target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage id="footer.privacy.title" />
              </a>
            ),
          }}
        />
      </AvenirAndHeavyText>
    </LoginFootnoteWrapper>
  );
}
