import { Button, InputAdornment, SxProps, TextField, Theme, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDebounce } from "usehooks-ts";
import CloseIcon from "#assets/svgs/close-dialog.svg?react";

interface Props {
  value: string;
  placeholder?: string;
  debounce?: number;
  sx?: SxProps<Theme>;
  onChange: (value: string) => void;
}

export default function SearchField({
  value,
  placeholder,
  debounce = 600,
  sx,
  onChange,
}: Props) {
  const [internalValue, setInternalValue] = useState(value);
  const debouncedValue = useDebounce(internalValue, debounce);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInternalValue(e.target.value);
  };

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue, onChange]);

  return (
    <TextField
      placeholder={placeholder}
      value={internalValue}
      InputProps={{
        endAdornment: (
          <Button
            variant="cta"
            onClick={() => setInternalValue("")}
            sx={{ display: internalValue ? "block" : "none" }}
          >
            <InputAdornment position="end">
              <DeleteIcon />
            </InputAdornment>
          </Button>
        ),
      }}
      onChange={handleSearch}
      sx={sx}
    />
  );
}

const DeleteIcon = styled(CloseIcon)`
  margin-right: -4px;
  g {
    path {
      fill: #00000090;
    }
  }
`;
