import { Button, ButtonProps, Tooltip, styled } from "@mui/material";
import colors from "#shared/config/theme/colors";
import classNames from "classnames";
import React from "react";
import IconChevronDown from "#assets/svgs/chevron-down.svg?react";
import rounding from "#shared/config/theme/rounding";

interface Props extends ButtonProps {
  tooltip?: string;
  selected?: boolean;
  isAIButton?: boolean;
  dropdown?: boolean;
}

export default function EditorButton({
  tooltip,
  dropdown,
  color = "secondary",
  isAIButton,
  selected,
  ...props
}: Props) {
  return (
    <Tooltip title={tooltip} placement="top" arrow={false}>
      <StyledButton
        className={classNames(
          {
            selected,
          },
          isAIButton && "-ai"
        )}
        endIcon={dropdown ? <IconChevronDown /> : null}
        color={color}
        {...props}
      >
        <span className="MuiButton-content">{props.children}</span>
      </StyledButton>
    </Tooltip>
  );
}

const StyledButton = styled(Button)`
  background-color: transparent;
  border-radius: ${rounding.default};
  height: 32px;
  font-weight: 500;

  svg {
    color: currentColor;
    width: 1rem;
    height: 1rem;
  }

  .MuiButton-startIcon {
    margin-right: 0.5rem;
  }

  .MuiButton-endIcon {
    padding-left: 0.5rem;
    margin-left: auto;
  }

  .MuiButton-startIcon,
  .MuiButton-endIcon {
    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  &.MuiButton-colorSecondary {
    color: ${colors.text.secondaryDark};

    &.selected {
      background-color: ${colors.grey[100]};
    }

    &:hover {
      background-color: ${colors.grey[50]};
    }
  }

  .MuiButton-content {
    display: flex;
  }

  &.-ai {
    color: transparent;

    .MuiButton-startIcon {
      color: ${colors.gradient.ai.start};
    }

    .MuiButton-content {
      background: ${colors.gradient.ai.value};
      background-clip: text;
      -webkit-background-clip: text;
    }
  }
`;
