import colors from "#shared/config/theme/colors";

export const getInputStyles = (primaryColor: string) => ({
  base: {
    border: `1px solid ${colors.input.border}`,
    transition: "border-color 0.2s ease-in-out",
  },
  focused: {
    borderColor: primaryColor,
    borderWidth: "1px",
  },
  disabled: {
    borderColor: colors.base.outline1,
  },
});
