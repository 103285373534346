import { getChannelRefFromParam } from "#components/routing/utils";
import { useFetchChannelByRefQuery } from "#features/channel/channelsAPI";
import { getOrganisationDomain, useGetWlaOrganisation } from "#utils/organisation";
import { useParams } from "react-router";

interface Props {
  skip?: boolean;
}

export default function useSelectedChannel({ skip = false }: Props = {}) {
  const params = useParams() as { channelRef?: string };
  const channelRef = getChannelRefFromParam(params.channelRef);
  const domainOrganisation = getOrganisationDomain(
    typeof window === "undefined" ? undefined : window.location.href
  );
  const { organisation } = useGetWlaOrganisation(domainOrganisation);

  const query = useFetchChannelByRefQuery(
    { channelRef: channelRef, organisationId: organisation?.organisationId },
    {
      skip: skip || !channelRef,
    }
  );

  const data =
    query.data && query.data.channelRef === channelRef ? query.data : undefined;
  const isFetchingProps = query.isFetching && !query.currentData;

  return { ...query, data, isFetching: isFetchingProps, channelRef };
}
