import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import LoginTitle from "#components/common/LoginComponents/LoginTitle";
import { LoginWrapperBase } from "#components/common/styled";
import { useSearchParams } from "react-router-dom";

import EmailLogin from "../../../../containers/EmailLogin";
import FooterTextContent from "./FooterTextContent";
import SSOLogin from "./SSOLogin";
import Toast from "#shared/components/ui/Toast";

export default function Authentication() {
  const prefix = "pages.login";

  const [searchParams] = useSearchParams();
  const errorType = searchParams.get("errorType");

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(typeof errorType === "string");

  const closeSnackbar = () => {
    setIsSnackbarOpen(false);
  };

  return (
    <>
      <LoginTitle prefixIntl={prefix} />
      <LoginWrapperBase>
        <EmailLogin />
        <SSOLogin />
        <FooterTextContent />
      </LoginWrapperBase>

      <Toast open={isSnackbarOpen} onClose={closeSnackbar} type="error" duration={10000}>
        <FormattedMessage
          id={`errors.${errorType}`}
          values={{
            b: (chunk: any) => <b>{chunk}</b>,
          }}
        />
      </Toast>
    </>
  );
}
