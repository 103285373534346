import MagicWriterView from "#components/common/Editor/extensions/MagicWriter/MagicWriterView";
import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { v4 as uuid } from "uuid";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    magicWriter: {
      setMagicWriter: () => ReturnType;
    };
  }
}

export const MagicWriter = Node.create({
  name: "magicWriter",
  group: "block",
  draggable: false,
  selectable: false,

  addOptions() {
    return {
      HTMLAttributes: {
        class: `node-${this.name}`,
      },
    };
  },

  addAttributes() {
    return {
      id: {
        default: undefined,
        parseHTML: (element) => element.getAttribute("data-id"),
        renderHTML: (attributes) => ({
          "data-id": attributes.id,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div.node-${this.name}`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      setMagicWriter:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs: {
                id: uuid(),
              },
            })
            .run(),
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(MagicWriterView);
  },
});

export default MagicWriter;
