import useSelectedChannel from "#components/pages/ChannelDetails/useSelectedChannel";
import { useGlobalAppProvider } from "#containers/Global/GlobalAppProvider";
import useGodView from "#containers/GodView/useGodView";
import { OrganisationPositionType, OrganisationStatus } from "#customTypes/types";
import { useFetchChannelByRefQuery } from "#features/channel/channelsAPI";
import { selectUser } from "#features/user/userSlice";
import { getIsPublicChannel } from "#utils/channel";
import { useSelectorTyped } from "#utils/index";
import { isUserSuperAdmin } from "#utils/userUtil";
import { useCallback, useMemo } from "react";

export default function useChannelPermissions(customChannelRef?: string) {
  const { profile } = useSelectorTyped(selectUser);
  const { organisation: godViewOrganisation } = useGodView();
  const { data: selectedChannel } = useSelectedChannel({ skip: !!customChannelRef });
  const { data: customChannel } = useFetchChannelByRefQuery(
    { channelRef: customChannelRef || "" },
    { skip: !customChannelRef }
  );

  const channel = customChannel || selectedChannel;

  const { ownedChannels: channels, positions } = useGlobalAppProvider();

  const isPersonalChannel = useMemo(() => {
    return !channel?.isVerified;
  }, [channel]);

  const hasPersonalChannel = useMemo(() => {
    return !!channels?.some((c) => !c.isVerified);
  }, [channels]);

  /* User can create or request new channel when being a part of organisation (admin or more) OR having no personal channels */
  const canCreateOrRequestChannel = useMemo(() => {
    const hasOrganisation = !!positions?.some(
      (c) =>
        c.organisation.status === OrganisationStatus.VERIFIED &&
        [OrganisationPositionType.Admin, OrganisationPositionType.Owner].includes(c.id)
    );

    return hasOrganisation || !hasPersonalChannel;
  }, [channels, positions, hasPersonalChannel]);

  /* Check if user can create a channel, if false he can request for new one */
  const canCreateChannelForOrganisation = useCallback(
    (organisationId?: number) => {
      if (godViewOrganisation?.id === organisationId) {
        return true;
      }

      if (!organisationId) {
        return !hasPersonalChannel;
      }

      const hasChannelsForOrganisation = !!channels?.some(
        (c) => c.organisationId === organisationId
      );

      return !hasChannelsForOrganisation;
    },
    [channels, hasPersonalChannel, godViewOrganisation]
  );

  const canEditChannel = useMemo(() => {
    // Exists in my owned channels
    const item = channels?.find((c) => c.id === channel?.id);

    if (!item) return false;

    if (godViewOrganisation?.id === item.organisationId) {
      return true;
    }

    // Check if channel is personal
    if (!item.isVerified) {
      return true;
    }

    // Check if user is admin or more for the channel's organisation
    const isOrganisationAdmin = positions?.some(
      (c) =>
        c.organisation.id === item.organisationId &&
        c.organisation.status === OrganisationStatus.VERIFIED &&
        [OrganisationPositionType.Admin, OrganisationPositionType.Owner].includes(c.id)
    );

    return !!isOrganisationAdmin;
  }, [channels, channel, godViewOrganisation, positions]);

  const isChannelResearcher = useMemo(() => {
    // Exists in my owned channels
    const item = channels?.find((c) => c.id === channel?.id);

    if (!item) {
      return false;
    }

    // Check if user is admin or more for the channel's organisation
    const isOrganisationResearcher = positions?.some(
      (c) =>
        c.organisation.id === item.organisationId &&
        c.organisation.status === OrganisationStatus.VERIFIED &&
        c.id === OrganisationPositionType.Researcher
    );

    return !!isOrganisationResearcher;
  }, [channels, channel, positions]);

  const canShareChannel = useMemo(() => {
    const hasScreener = !!channel?.screener?.url || !!channel?.screener?.briefRef;
    return !!channel?.isDiscoverable || hasScreener;
  }, [channel]);

  const canJoinChannel = useMemo(() => {
    // Exists in my owned channels
    const item = channels?.find((c) => c.id === channel?.id);

    if (item) {
      return false;
    }

    // Check if channel is public
    if (!channel || channel.isPrivate === false) {
      return false;
    }

    // Check if user is member of non-discoverable channel
    return !channel.isMember ? channel.isDiscoverable : true;
  }, [channels, channel]);

  const isChannelMember = useMemo(() => {
    // Exists in my owned channels
    if (!channel) {
      return false;
    }

    if (godViewOrganisation?.id === channel.organisationId) {
      return true;
    }

    return channel.isMember;
  }, [channel, godViewOrganisation]);

  const canSeeMembers = useMemo(() => {
    // Exists in my owned channels
    const item = channels?.find((c) => c.id === channel?.id);

    if (!item) {
      return true;
    }

    const isPublicChannel = getIsPublicChannel(item);

    return !isPublicChannel;
  }, [channels, channel]);

  const canCreateBrief = useMemo(() => {
    return canEditChannel && !isPersonalChannel;
  }, [isPersonalChannel, canEditChannel]);

  const canSetScreener = useMemo(() => {
    const isSuperdmin = isUserSuperAdmin(profile);

    if (!isSuperdmin) {
      return false;
    }

    const item = channels?.find((c) => c.id === channel?.id);

    if (!item) {
      return true;
    }

    const isPublicChannel = getIsPublicChannel(item);

    return !isPersonalChannel && !isPublicChannel;
  }, [isPersonalChannel, profile]);

  return {
    canCreateChannelForOrganisation,
    canCreateOrRequestChannel,
    canEditChannel,
    canResearchChannel: isChannelResearcher || canEditChannel,
    isChannelResearcher,
    canJoinChannel,
    canShareChannel,
    canSeeMembers,
    hasPersonalChannel,
    isPersonalChannel,
    isChannelMember,
    canCreateBrief,
    canSetScreener,
  };
}
