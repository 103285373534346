import { createTheme } from "@mui/material";
import colors from "../theme/colors";

const commonTheme = createTheme({
  palette: {
    primary: {
      main: colors.brand.default,
      light: colors.brand[400],
      dark: colors.brand[800],
    },
    success: {
      main: colors.success.default,
      light: colors.success[400],
      dark: colors.success[600],
    },
    error: {
      main: colors.error.default,
      light: colors.error[400],
      dark: colors.error[600],
    },
    text: {
      primary: colors.text.primaryDark,
      secondary: colors.text.secondaryDark,
      disabled: colors.text.disabledDark,
    },
    grey: colors.grey,
  },
  typography: {
    allVariants: {
      fontFamily: "'Avenir', sans-serif",
      textTransform: "none",
    },
  },
  spacing: 4,
});

export default commonTheme;
