import { useMemo } from "react";
import env from "#env";
import useSelectedChannel from "#components/pages/ChannelDetails/useSelectedChannel";
import useShare from "#utils/useShare";
import { getChannelUrl } from "#components/routing/utils";

export default function useShareChannel() {
  const { data: channel } = useSelectedChannel();

  const data = useMemo(
    () => ({
      title: channel?.isVerified
        ? "Hop on board, share your insights, and snag sweet perks for helping shape the future of products and services!"
        : "Come join our fun squad! We’re all about sharing opinions and seeing where our collective thoughts can take us. Can't wait for you to join.",
      url: `${env.VITE_BASE_URL_UI}/invite/${channel?.publicInviteToken}`,
      redirectUrl: `${env.VITE_BASE_URL_UI}/${getChannelUrl(channel?.channelRef)}`,
    }),
    [channel]
  );

  return useShare(data);
}
