// modules
import React, { FormEvent } from "react";

import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";

// shared
import { Button } from "#shared/components/ui/Button";

// components
import { WrapperCenteredForm } from "#components/common/styled";

import CircularProgress from "@mui/material/CircularProgress";
import { ComposedHookEmailLogin } from "../../../../../types/hooks";
import { TextField } from "#shared/components/ui";
import { Navigate } from "react-router";
import GuestLogin from "./GuestLogin";

export default function EmailLogin({ useSendEmail }: ComposedHookEmailLogin) {
  const { defaultEmail, useEmailForm, autoSend } = useSendEmail;
  const { sendingEmail, onSendingEmail, useEmail, useErrorMessage, hasEmailBeenSent } =
    useEmailForm;
  const [email, setUserEmail] = useEmail;
  const [errorMessage] = useErrorMessage;
  const intl = useIntl();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSendingEmail();
  };

  if (email && (autoSend || hasEmailBeenSent)) {
    return <Navigate to={`email?email=${email}`} />;
  }

  return (
    <WrapperCenteredForm style={{ width: "100%", gap: "1rem" }} onSubmit={onSubmit}>
      <TextField
        id="email"
        name="email"
        formProps={{ fullWidth: true }}
        label={intl.formatMessage({ id: "pages.email_login.label" })}
        defaultValue={defaultEmail}
        error={!!errorMessage}
        error_message={
          errorMessage &&
          intl.formatMessage({
            id: errorMessage,
          })
        }
        placeholder={intl.formatMessage({
          id: `pages.screener_success.email.placeholder`,
        })}
        fullWidth
        onChange={(event) => setUserEmail(event.target.value)}
      />

      <div className="buttonContainer">
        <Button disabled={sendingEmail} sx={{ flex: 2 }} type="submit">
          {sendingEmail ? (
            <CircularProgress size={32} className="circularProgress" />
          ) : (
            <FormattedMessage id="ui.buttons.next" />
          )}
        </Button>
        <GuestLogin />
      </div>
    </WrapperCenteredForm>
  );
}
