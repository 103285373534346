import { styled } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import colors from "../../../config/theme/colors";
import React from "react";
import { FormattedMessage } from "react-intl";

interface Props {
  label: string;
  name?: string;
  additionalLabel?: string;
  optional?: boolean;
  optionalLabel?: string;
}

export default function FormLabel({
  name,
  label,
  additionalLabel,
  optional,
  optionalLabel,
}: Props) {
  return (
    <InputLabel htmlFor={name}>
      {label}
      {optional && (
        <OptionalLabel>
          {optionalLabel ?? <FormattedMessage id="form.optional" />}
        </OptionalLabel>
      )}
      {additionalLabel && <OptionalLabel>{additionalLabel}</OptionalLabel>}
    </InputLabel>
  );
}

const OptionalLabel = styled("span")`
  color: ${colors.grey.default};
  margin-left: 0.25rem;
`;
