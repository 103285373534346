import useZodForm from "#shared/utils/hooks/useZodForm";
import { z } from "zod";
import { AIChatMetadataData } from "#components/common/AIChat/types";
import { BriefResponseType } from "#customTypes/briefBuilder";
import {
  BRIEF_AUDIENCE_AGE_MAX,
  BRIEF_AUDIENCE_AGE_MIN,
} from "#components/pages/BriefBuilder/Audience/utils";

const schema = z.object({
  title: z.string().min(1).max(100),
  prompt: z.string().min(5).max(500),
  countries: z.number().array(),
  gender: z.string().array(),
  minAge: z.number(),
  maxAge: z.number(),
  responseType: z.nativeEnum(BriefResponseType),
});

export type AIChatTemplateForm = z.infer<typeof schema>;

export default function useAIChatTemplateForm(data?: AIChatMetadataData) {
  const form = useZodForm<AIChatTemplateForm>({
    schema,
    defaultValues: {
      title: data?.title || "",
      prompt: data?.objectives.map((o) => `- ${o}\n`).join("") || "",
      countries: data?.target_audience.country_ids || [],
      gender: data?.target_audience.gender || [],
      minAge: data?.target_audience.clean_age_min || BRIEF_AUDIENCE_AGE_MIN,
      maxAge: data?.target_audience.clean_age_max || BRIEF_AUDIENCE_AGE_MAX,
      responseType: data?.response_type || BriefResponseType.Comment,
    },
    options: {
      validateOnChange: true,
    },
  });

  return form;
}
