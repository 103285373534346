import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

export default function useIntersectionObserver<T>({
  root = null,
  rootMargin = "0px",
  threshold = 0,
}): [
  Dispatch<SetStateAction<T | undefined>>,
  IntersectionObserverEntry | undefined
] {
  const [entry, updateEntry] = useState<
    IntersectionObserverEntry | undefined
  >();
  const [nodeRef, setNodeRef] = useState<T>();
  const observer = useRef(
    typeof window !== "undefined"
      ? new IntersectionObserver(([entry]) => updateEntry(entry), {
          root,
          rootMargin,
          threshold,
        })
      : null
  );

  useEffect(() => {
    if (observer) {
      const { current: currentObserver } = observer;
      if (currentObserver) {
        currentObserver.disconnect();
        const refElement = nodeRef as HTMLElement;
        if (nodeRef) currentObserver.observe(refElement);

        return () => currentObserver.disconnect();
      }
    }
  }, [nodeRef]);

  return [setNodeRef, entry];
}
