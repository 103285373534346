import ReactGA from "react-ga4";
import { BRIEF_TYPES } from "#constants/common";

export enum GaEvents {
  userchannel = "UserChannel",
  userorganisation = "UserOrganisation",
  userwla = "UserWLA",
  briefinfo = "brief_info",
  openmyprofile = "open_my_profile",
  unlikebriefbuttonpress = "unlike_brief_button_press",
  likebriefbuttonpress = "like_brief_button_press",
  openbriefbuttonpress = "open_brief_button_press",
  openbriefid = "open_brief_id",
  openbriefsubmitbuttonpress = "open_brief_submit_button_press",
  openbriefsurveybuttonpress = "open_brief_survey_button_press",
  openbrandprofile = "open_brand_profile",
  openeditprofilebuttonpress = "open_edit_profile_button_press",
  opensettingsbuttonpress = "open_settings_button_press",
  dropoutsurvey = "dropout_survey",
  dropoutonquestionsurvey = "dropout_on_question_survey",
  dropoutnoanswerssurvey = "dropout_no_answers_survey",
  dropoutexitalertsurvey = "dropout_exit_alert_survey",
  completedsurvey = "completed_survey",
  opensurvey = "open_survey",
  apply_for_organisation = "apply_for_organisation",
  business_lab_access = "business_lab_access",
  knowledge_ai_access = "knowledge_ai_access",
  notifybriefcreation = "notify_brief_creation",
  createbriefplaceholder = "create_brief_placeholder",
  createbriefchat = "create_brief_chat",
  createChannel = "create_channel",
  requestChannel = "request_channel",
}

const gaTrackEvent = (
  event_name: string,
  parameter_name: string,
  parameter_value: string
) => {
  const event_params = {
    action: "ui_action",
    name: event_name,
    value: parameter_value,
  };
  ReactGA.event(event_name, event_params);
};

const gaUserId = (userref: string) => {
  ReactGA.set({ userId: userref });
};

const gaUserRole = (role: string) => {
  ReactGA.ga("set", "user_properties", {
    UserRole: role,
  });
};

const gaUserWlaProperties = (wla_name: string, wla_id: number) => {
  ReactGA.ga("set", "user_properties", {
    user_wla: wla_id + " - " + wla_name,
  });
};

const gaUserChannel = (
  user_channel: string,
  user_channel_id: number,
  user_channel_org_id: number
) => {
  const event_params = {
    user_channel: user_channel,
    user_channel_id: user_channel_id,
    user_channel_org_id: user_channel_org_id,
  };
  ReactGA.event(GaEvents.userchannel, event_params);
};

const gaUserOrg = (user_organisation: string, user_channel_org_id: number) => {
  const event_params = {
    user_organisation: user_organisation,
    user_channel_org_id: user_channel_org_id,
  };
  ReactGA.event(GaEvents.userorganisation, event_params);
};

const gaUserWLA = (wla_name: string, wla_id: number) => {
  const event_params = {
    user_wla: wla_name,
    user_wla_id: wla_id,
  };
  ReactGA.event(GaEvents.userwla, event_params);
};

const gaBriefInfo = (internal_name?: string, open_brief_type?: number) => {
  if (internal_name === undefined || open_brief_type === undefined) {
    return;
  }
  let briefType = BRIEF_TYPES[open_brief_type];
  briefType = briefType[0].toUpperCase() + briefType.slice(1) + " Brief";

  const event_params = {
    open_brief: internal_name,
    open_brief_type: briefType,
  };
  ReactGA.event(GaEvents.briefinfo, event_params);
};

export {
  gaTrackEvent,
  gaUserId,
  gaUserRole,
  gaUserWlaProperties,
  gaUserChannel,
  gaUserOrg,
  gaUserWLA,
  gaBriefInfo,
};
