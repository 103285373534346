import { useSearchParams } from "react-router-dom";

export const useIsMobileApp = () => {
  const [searchParams] = useSearchParams();
  const isMobileApp = searchParams.get("app") === "1";
  const platform = searchParams.get("platform") as "ios" | "android" | null;

  const isMobileIOS = isMobileApp && platform === "ios";

  return { isMobileApp, isMobileIOS };
};
