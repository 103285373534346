import { styled } from "@mui/material/styles";
import colors from "#shared/config/theme/colors";

export const WrapperFilter = styled("div")(
  ({ theme }) => `
  & .filters {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 1.3rem;
  cursor: pointer;
  
  ${theme.breakpoints.down("md")} {
    &.main {
      padding: 0.75rem;
      box-sizing: border-box;
      margin-bottom: 0;
    }
  }
 

  &.private {
    margin-bottom: 0.8rem;
  }

  &.requested {
    opacity: 0.4;
    pointer-events: none;
    
    * {
      cursor: default;
    }
  }

  & > div div {
    display: flex;
    flex-direction: column;
  }

  & .circle {
    cursor: pointer;
    flex-shrink: 0;
  }

  & .item {
    margin-left: 0.75rem;
    cursor: pointer;

    & .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1rem;
      font-family: Avenir-heavy;
      
      & span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      

      .channel-name {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
      }
      

      ${theme.breakpoints.down("sm")} {
        font-size: 0.875rem;
      }

      & > span:hover {
        color: #262626da;
      }

      &.selected {
        color: ${theme.palette.primary.main};
        & > span:hover {
          color: inherit;
        }
      }

      &.error {
        color: ${theme.palette.error.main};
        & > span:hover {
          color: inherit;
        }
      }
    }
  }

  & .members {
    font-size: 0.75rem;
  }

  &.main:not(.selected) {
    color: ${colors.base.black};

    &:hover {
      span {
        color: ${colors.text.secondaryDark};
      }

      svg {
        color: ${colors.text.secondaryDark};
      }
    }
  }

  & .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;

    & svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
`
);
