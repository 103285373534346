import useChannelPermissions from "#components/pages/ChannelDetails/useChannelPermissions";
import { useGlobalAppProvider } from "#containers/Global/GlobalAppProvider";
import useGodView from "#containers/GodView/useGodView";
import { OrganisationPositionType, OrganisationStatus } from "#customTypes/types";
import { useCallback, useMemo } from "react";

export default function useCreateChannelOrganisations() {
  const { positions } = useGlobalAppProvider();
  const { hasPersonalChannel } = useChannelPermissions();
  const { organisation: godViewOrganisation } = useGodView();

  const availableOrganisations = useMemo(
    () =>
      positions?.filter((c) => {
        const canEditOrganisation = [
          OrganisationPositionType.Owner,
          OrganisationPositionType.Admin,
        ].includes(c.id);

        const isAvailableOrganisation = [
          OrganisationStatus.VERIFIED,
          OrganisationStatus.PENDING_VERIFICATION,
        ].includes(c.organisation.status);

        return canEditOrganisation && isAvailableOrganisation;
      }) || [],
    [positions]
  );

  const verifiedOrganisations = useMemo(
    () =>
      availableOrganisations.filter(
        (c) => c.organisation.status === OrganisationStatus.VERIFIED
      ),
    [availableOrganisations]
  );

  const getDefaultOrganisation = useCallback(() => {
    if (godViewOrganisation) {
      return godViewOrganisation.id;
    }

    if (verifiedOrganisations.length === 1) {
      return verifiedOrganisations[0].organisation.id;
    }

    return -1;
  }, [godViewOrganisation, verifiedOrganisations]);

  const totalAvailableOrganisations =
    verifiedOrganisations.length + (!hasPersonalChannel ? 1 : 0);

  const skipSelectOrganisationStep = totalAvailableOrganisations < 2;

  return {
    verifiedOrganisations,
    skipSelectOrganisationStep,
    getDefaultOrganisation,
  };
}
