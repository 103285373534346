import { EditorCommandGroup } from "#components/common/Editor/types";
import IconHeading1 from "#assets/svgs/heading1.svg?react";
import IconHeading2 from "#assets/svgs/heading2.svg?react";
import IconListBullet from "#assets/svgs/list-bullet.svg?react";
import IconListNumber from "#assets/svgs/list-number.svg?react";
import IconBlockQuote from "#assets/svgs/block-quote.svg?react";
import IconTable from "#assets/svgs/table.svg?react";
import IconImage from "#assets/svgs/image.svg?react";
import IconHorizontalRule from "#assets/svgs/horizontal-rule.svg?react";
import IconMagicWrite from "#assets/svgs/magic-write.svg?react";
import IconMagicImage from "#assets/svgs/magic-image.svg?react";

export const EDITOR_COMMANDS: EditorCommandGroup[] = [
  {
    name: "ai",
    title: "sections.bulbshare_ai",
    commands: [
      {
        name: "magicWriter",
        label: "commands.magic_write",
        Icon: IconMagicWrite,
        isAICommand: true,
        description: "Generate text with AI",
        shouldBeHidden: (editor) => editor.isActive("columns"),
        action: (editor) => editor.chain().focus().setMagicWriter().run(),
      },
      {
        name: "magicImage",
        label: "commands.magic_image",
        Icon: IconMagicImage,
        isAICommand: true,
        description: "Generate an image from text",
        shouldBeHidden: (editor) => editor.isActive("columns"),
        action: (editor) => editor.chain().focus().setMagicImage().run(),
      },
    ],
  },
  {
    name: "format",
    title: "sections.format",
    commands: [
      {
        name: "heading1",
        label: "commands.heading1",
        description: "High priority section title",
        aliases: ["h1"],
        Icon: IconHeading1,
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 1 }).run();
        },
      },
      {
        name: "heading2",
        label: "commands.heading2",
        description: "Medium priority section title",
        aliases: ["h2"],
        Icon: IconHeading2,
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 2 }).run();
        },
      },
      {
        name: "bulletList",
        label: "commands.bullet_list",
        description: "Unordered list of items",
        aliases: ["ul"],
        Icon: IconListBullet,
        action: (editor) => {
          editor.chain().focus().toggleBulletList().run();
        },
      },
      {
        name: "numberedList",
        label: "commands.numbered_list",
        description: "Ordered list of items",
        aliases: ["ol"],
        Icon: IconListNumber,
        action: (editor) => {
          editor.chain().focus().toggleOrderedList().run();
        },
      },
      {
        name: "blockquote",
        label: "commands.blockquote",
        description: "Element for quoting",
        Icon: IconBlockQuote,
        action: (editor) => {
          editor.chain().focus().setBlockquote().run();
        },
      },
    ],
  },
  {
    name: "insert",
    title: "sections.insert",
    commands: [
      {
        name: "table",
        label: "commands.table",
        description: "Insert a table",
        Icon: IconTable,
        shouldBeHidden: (editor) => editor.isActive("columns"),
        action: (editor) => {
          editor
            .chain()
            .focus()
            .insertTable({ rows: 3, cols: 3, withHeaderRow: false })
            .run();
        },
      },
      {
        name: "image",
        label: "commands.image",
        description: "Insert an image",
        aliases: ["img"],
        Icon: IconImage,
        action: (editor) => {
          editor.chain().focus().setImageUpload().run();
        },
      },
      {
        name: "horizontalRule",
        label: "commands.horizontal_rule",
        description: "Insert a horizontal divider",
        aliases: ["hr"],
        Icon: IconHorizontalRule,
        action: (editor) => {
          editor.chain().focus().setHorizontalRule().run();
        },
      },
    ],
  },
];

export default EDITOR_COMMANDS;
