import { channelsApi } from "#features/channel/channelsAPI";
import { feedApi } from "#features/feed/feedAPI";
import { selectGodView } from "#features/superAdmin/godViewSlice";
import { organisationsApi } from "#features/organisation/organisationAPI";
import { organisationPositionsApi } from "#features/organisationPositions/organisationPositionsAPI";
import { useSelectorTyped } from "#utils/index";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import usePrevious from "#shared/utils/hooks/usePrevious";
import { configureAxiosHeaders } from "#apis/axios";
import { AppDispatch } from "src/store";
import { useQueryClient } from "@tanstack/react-query";

export default function useRefetchApp() {
  const dispatch = useDispatch<AppDispatch>();

  const godViewId = useSelectorTyped(selectGodView).selectedOrganisation?.id;
  const previousGodViewId = usePrevious(godViewId);
  const queryClient = useQueryClient();

  const resetQueries = useCallback(async () => {
    // TODO: reset RQ cached queries too
    await Promise.all([
      dispatch(channelsApi.util.resetApiState()),
      dispatch(organisationPositionsApi.util.resetApiState()),
      dispatch(organisationsApi.util.resetApiState()),
      dispatch(feedApi.util.resetApiState()),
      dispatch(channelsApi.endpoints.fetchMyOwnedChannels.initiate({})),
      dispatch(
        organisationPositionsApi.endpoints.fetchMyOrganisationPositions.initiate({})
      ),
    ]);
    queryClient.removeQueries();
    configureAxiosHeaders({ godViewId });
  }, [godViewId]);

  useEffect(() => {
    if (!previousGodViewId && !godViewId) {
      return;
    }

    resetQueries();
  }, [godViewId]);
}
