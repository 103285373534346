// modules
import React from "react";
import { Outlet } from "react-router";
import { styled } from "@mui/material";
import { useLocation } from "react-router-dom";

// components
import { getRoutePathname, isProtectedPage } from "../../../utils";
import HeaderContainer from "#containers/layout/Header";
import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";
import DownloadModal from "#containers/DownloadModal";
import GodViewGlobalListener from "#containers/GodView/GodViewGlobalListener";
import GlobalAppProvider from "#containers/Global/GlobalAppProvider";
import useRefetchApp from "#containers/GodView/useRefetchApp";
import MagicButtonWrapper from "#containers/layout/MagicButton";
import { identifyPathname } from "#utils/routeHelpers";

export default function WrapperPrivateRoute() {
  const location = useLocation();
  const routePath = getRoutePathname();
  const { isTabletOrMobile } = useMediaQueryProvider();

  useRefetchApp();

  const isBriefBuilder = identifyPathname.isBriefBuilder(location.pathname);
  const isBusinessCentre = identifyPathname.isBusinessCentre(location.pathname);
  const isSurveyPreview = identifyPathname.isSurveyPreview(location.pathname);

  const header = () => {
    if (isTabletOrMobile && isBusinessCentre) {
      return null;
    }

    if (isBriefBuilder || isSurveyPreview) {
      return null;
    }

    return <HeaderContainer isProtectedPage={isProtectedPage(routePath)} />;
  };

  const content = () => {
    if (isBriefBuilder) {
      return (
        <React.Suspense>
          <Outlet />
        </React.Suspense>
      );
    }

    return (
      <Content>
        <React.Suspense>
          <Outlet />
        </React.Suspense>
      </Content>
    );
  };

  return (
    <>
      <GlobalAppProvider>
        {header()}
        {content()}
        <MagicButtonWrapper />
      </GlobalAppProvider>
      <DownloadModal />
      <GodViewGlobalListener />
    </>
  );
}

const Content = styled("div")`
  width: 100%;
  min-height: calc(100dvh - 6.25rem);
  display: flex;
  align-items: center;
  justify-content: center;
`;
