import env from "#env";
import { ProfileDetails } from "../../types/db";

export default async function fetchUserByFingerprint(
  fingerprintId: string
): Promise<ProfileDetails> {
  let user;
  try {
    const response = await fetch(
      `${env.VITE_API_BASE_URL}/community/users/fingerprint/${fingerprintId}`
    );
    user = await response.json();
  } catch (e) {
    /* empty */
  }
  return user;
}
