import React from "react";
import { styled } from "@mui/material/styles";

import { AvenirAndHeavyText } from "#components/common/styled";
import { FormattedMessage } from "react-intl";
import { TitleH1 } from "../Titles";

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.5rem;
`;

type HeaderTextProps = {
  prefixIntl: string;
  email?: string;
};

export default function LoginTitle({ prefixIntl, email }: HeaderTextProps) {
  return (
    <Wrapper>
      <TitleH1 style={{ textAlign: "center" }}>
        <FormattedMessage id={`${prefixIntl}.content.title`} />
      </TitleH1>

      <AvenirAndHeavyText>
        <FormattedMessage
          id={`${prefixIntl}.content.p`}
          values={{
            email: <b className="heavy">{email}</b>,
            b: (chunk) => <span className="heavy">{chunk}</span>,
          }}
        />
      </AvenirAndHeavyText>
    </Wrapper>
  );
}
