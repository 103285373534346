export const ButtonIDs = {
  header: { closeButton: "header-closeButton" },
  briefDetails: {
    closeButton: "briefDetails-closeButton",
    joinBriefContent: "briefDetails-joinBriefContent",
    joinBriefSidebar: "briefDetails-joinBriefSidebar",
    briefComments: "briefDetails-briefComments",
    bulbshares: "briefDetails-bulbshares",
    share: "briefDetails-share",
  },
};
