/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactNode, createContext, useContext, useMemo, useState } from "react";

interface ContextProps {
  isAiLoading: boolean;
  aiError: string | null;
  setIsAiLoading: (value: boolean) => void;
  setAiError: (value: string | null) => void;
}

const EditorContext = createContext<ContextProps>({
  aiError: null,
  isAiLoading: false,
  setIsAiLoading: () => {},
  setAiError: () => {},
});

interface ProviderProps {
  children: ReactNode;
}

export function TiptapEditorProvider({ children }: ProviderProps) {
  const [isAiLoading, setIsAiLoading] = useState(false);
  const [aiError, setAiError] = useState<string | null>(null);

  const providerValue = useMemo(
    () => ({
      isAiLoading,
      aiError,
      setIsAiLoading,
      setAiError,
    }),
    [isAiLoading, aiError, setIsAiLoading, setAiError]
  );

  return (
    <EditorContext.Provider value={providerValue}>{children}</EditorContext.Provider>
  );
}

export function useTiptapEditorProvider(): ContextProps {
  const context = useContext(EditorContext);
  if (!context) {
    throw new Error("useTiptapEditorProvider must be used within a TiptapEditorProvider");
  }
  return context;
}
