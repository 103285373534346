import React from "react";
import { Box, InputAdornment, SxProps, Theme, styled } from "@mui/material";
import { LoadingButton, TextField } from "#shared/components/ui";
import colors from "#shared/config/theme/colors";
import IconSend from "#assets/svgs/send.svg?react";
import { toRem } from "#shared/utils";
import { useSelectorTyped } from "#utils/index";
import { selectUser } from "#features/user/userSlice";
import Avatar from "#components/common/Avatar";
import { IMAGE_TYPES } from "#customTypes/types";
import useAIChatInput from "#components/common/AIChat/hooks/useAIChatInput";
import { AIChatStep } from "#components/common/AIChat/types";
import useTranslation from "#utils/useTranslation";
import IconCircleStop from "#assets/svgs/circle-stop.svg?react";
import Tooltip from "#shared/components/ui/Tooltip";
import rounding from "#shared/config/theme/rounding";
import classNames from "classnames";

const MIN_LENGTH = 2;
const MAX_LENGTH = 1000;

interface Props {
  sx?: SxProps<Theme>;
  className?: string;
}

export default function AIChatInput({ sx, className }: Props) {
  const { t } = useTranslation();
  const {
    step,
    prompt,
    loading,
    stopGenerating,
    setPrompt,
    inputDisabled,
    handleSubmit,
  } = useAIChatInput();
  const user = useSelectorTyped(selectUser);

  // const loading = true;

  const sendDisabled = !loading && (!prompt || prompt.length < MIN_LENGTH);

  const inputPlaceholder = () => {
    switch (step) {
      case AIChatStep.Welcome:
        return t("ui.ai_chat.placeholders.welcome");
      case AIChatStep.ConfirmChannel:
        return t("ui.ai_chat.placeholders.confirm_channel");
      case AIChatStep.SelectChannel:
        return t("ui.ai_chat.placeholders.select_channel");
      default:
        return t("ui.ai_chat.placeholders.default");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleConfirm();
    }
  };

  const handleConfirm = () => {
    if (loading) {
      stopGenerating();
      return;
    }

    handleSubmit();
  };

  return (
    <Wrapper className={classNames(className, loading && "-loading")} sx={sx}>
      <TextField
        fullWidth
        maxLength={MAX_LENGTH}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Avatar
                uri={user?.profile.avatar || ""}
                type={IMAGE_TYPES.USER_AVATAR}
                diameter={1.5}
              />
            </InputAdornment>
          ),
        }}
        onKeyDown={handleKeyDown}
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        placeholder={inputPlaceholder()}
        multiline
        disabled={loading || inputDisabled}
        sx={{
          ".MuiInputBase-input": {
            maxHeight: "100px",
            overflowY: "auto !important",
          },
        }}
      />

      <SendButtonWrapper>
        <Tooltip
          title={t(
            !loading
              ? "ui.ai_chat.tooltips.send_message"
              : "ui.ai_chat.tooltips.stop_generating"
          )}
          hidden={sendDisabled}
        >
          <SendButton
            variant="contained"
            color="primary"
            loading={false}
            disabled={sendDisabled}
            onClick={handleConfirm}
          >
            {!loading ? <IconSend /> : <IconCircleStop />}
          </SendButton>
        </Tooltip>
      </SendButtonWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  position: relative;
  width: 100%;

  --border-width: 2px;
  --background: ${colors.base.white};
  --border-color: ${colors.grey[200]};

  @property --angle {
    syntax: "<angle>";
    inherits: false;
    initial-value: 0deg;
  }

  position: relative;
  padding: var(--border-width);
  border: var(--border-width) transparent;
  border-radius: ${rounding.default};
  background-origin: border-box;
  -webkit-background-clip: content-box, border-box;
  background-clip: content-box, border-box;
  background-image: linear-gradient(var(--background), var(--background)),
    linear-gradient(315deg, var(--border-color) 0%, var(--border-color) 100%);

  &:has(.MuiInputBase-root.Mui-focused) {
    background-image: linear-gradient(var(--background), var(--background)),
      ${colors.gradient.ai.value};
  }

  &.-loading {
    background-image: linear-gradient(var(--background), var(--background)),
      conic-gradient(
        from var(--angle),
        ${colors.gradient.ai.start},
        ${colors.gradient.ai.end},
        ${colors.gradient.ai.start}
      );
    animation: 2.5s spin linear infinite;
  }

  &:has(.MuiInputBase-root.Mui-disabled) {
    --border-color: ${colors.grey[100]};
  }

  @keyframes spin {
    from {
      --angle: 0deg;
    }
    to {
      --angle: 360deg;
    }
  }

  .MuiInputBase-root {
    background: ${colors.base.white};
    background-clip: padding-box;

    .MuiInputBase-input {
      padding-right: ${toRem(40)};
    }

    .MuiOutlinedInput-notchedOutline {
      border: none !important;
    }

    .MuiInputAdornment-positionStart {
      align-self: flex-start;
      margin-top: 0.6rem;

      svg {
        color: ${colors.brand.default};
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    &.Mui-disabled {
      cursor: not-allowed;
    }
  }
`;

const SendButtonWrapper = styled(Box)`
  position: absolute;
  right: 0.5rem;
  bottom: ${toRem(8)};
  width: ${toRem(40)};
  height: ${toRem(40)};
`;

const SendButton = styled(LoadingButton)`
  width: ${toRem(40)} !important;
  height: ${toRem(40)} !important;
  min-width: 0;
`;
