import { BriefResponseType } from "#customTypes/briefBuilder";

export enum AIChatStep {
  Welcome = "welcome",
  SelectChannel = "select_channel",
  ConfirmChannel = "confirm_channel",
  BriefChat = "brief_chat",
}

export enum AIChatMessageType {
  Text = "text",
  ChannelList = "channel_list",
  ConfirmChannel = "confirm_channel",
  BriefTemplate = "brief_builder_chat_template",
  Error = "error",
}

export enum AIChatRole {
  User = "user",
  Assistant = "assistant",
}

export enum AIImageGenerationError {
  InappropriatePrompt = "not allowed by our safety system",
  RateLimit = "err_too_many_image_generations",
}

export interface AIChatChannel {
  channelRef: string;
  channelName?: string;
}

export interface AIChatMessageProps {
  role: AIChatRole;
  content: string;
  skip?: boolean; // FE only - skip message from being sent to the server
  loading?: boolean; // FE only
  metadata?: AIChatMessageMetadata;
}

export interface AIChatMessageMetadata {
  type: AIChatMessageType;
  data?: AIChatMetadataData;
}

export interface AIChatMetadataData {
  title: string;
  objectives: string[];
  target_audience: {
    countries: string[];
    gender: string[];
    country_ids: number[];
    clean_age_min: number;
    clean_age_max: number;
  };
  methodology: string;
  response_type: BriefResponseType;
}

export interface AIChatTemplate {
  id: string;
  title: string;
  prompt: string;
}
